<template>
    <div class="banner" v-if="value">
        <div>
            <i class="fas fa-warning banner-icon"></i> Please correct the errors
            on this page before moving on
        </div>
        <btn
            @click="$emit('input', false)"
            class="btn-icon btn-xs btn-soft-danger mr-0"
            color=""
        >
            <i class="fas fa-times"> </i>
        </btn>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
        },
    },
};
</script>
