function prettifyNumber(inputVal) {
    var value;
    if (inputVal === null) return inputVal;
    value = inputVal.toString().replace(/,/g, '');
    var parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    value = parts.join('.');
    return value;
}

var numberInputMixin = {
    created() {
        this.$emit('update:data', prettifyNumber(this.data));
    },

    methods: {
        handleInput(inputVal) {
            if (this.commaSeparators == false) {
                this.$emit('update:data', inputVal);
            }
            else {
                this.$emit('update:data', prettifyNumber(inputVal));
            }
        },
    },
};

export { prettifyNumber, numberInputMixin };
