<template>
    <button
        type="button"
        ref="button"
        class="btn"
        :class="getClasses"
        href="#"
        @click="$emit('click', $event)"
        :disabled="getDisabled"
    >
        <loading v-if="loading" small class="pr-1 pb-1"> </loading>
        <slot> </slot>
    </button>
</template>

<script>
export default {
    props: {
        block: {
            type: Boolean,
            default: false,
        },
        app: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'md',
        },
        color: {
            type: String,
            default: 'primary',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disableOnFormsPurchased: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        getDisabled() {
            return (
                this.loading ||
                this.disabled ||
                (this.disableOnFormsPurchased &&
                    this.$store.getters.formsPurchased)
            );
        },

        getClasses() {
            var classes = [];

            if (this.app) {
                classes.push(`btn-app btn-app-${this.size}`);
            } else {
                classes.push(`btn-${this.size}`);
            }

            classes.push(`btn-${this.color}`);

            if (this.block) {
                classes.push(`btn-block`);
            }

            return classes;
        },
    },
};
</script>
