<template>
    <select-input
        :value="data"
        @input="$emit('update:data', $event)"
        :id="getInputId(field)"
        @blur="$emit('blur')"
        :disabled="$store.getters.formsPurchased"
        :validation-rules="field.validation_rules"
        :choices="field.choices"
    >
        <template v-slot:label>
            <dyn-render v-if="field.label" :html="field.label"></dyn-render>
            <span v-else-if="fillTop">&shy;</span>
        </template>
    </select-input>
</template>

<script>
export default {
    props: {
        field: { type: Object, required: true },
        data: { required: true },
        fillTop: { type: Boolean, default: false },
    },
    data() {
        return {
            currentOption: '',
            data1: this.data,
            name2: this.field.name,
            internalVal: null,
        };
    },
    watch: {
        value: function (newValue) {
            this.currentOption = newValue;
        },
        show: function () {
            this.data1 = '';
        },
    },
    mounted() {
        this.internalVal = this.data;
        this.$watch('data', (val) => {
            this.internalVal = val;
        });
    },
};
</script>
