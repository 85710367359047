import incomePageModules from './pages/income';
import basicPageModules from './pages';
import isOrdered from './page-sequence-utilities';
import cleanFormData from '@/api/clean-form-data';
import router from '@/router';
import sendConversion from '@/views/send_conversion';
import { event } from 'vue-analytics';
import versioningTimer from '@/services/versioning';
import submitErrorLog from '@/services/error-logs';

function getShouldSubmit(
    currentPage,
    lastCompletedPage,
    dirty,
    formsPurchased,
    direction,
) {
    if (dirty) {
        return true;
    }
    var result = isOrdered(currentPage, lastCompletedPage);
    if (result.same && direction == 'back') {
        return false;
    }

    return !result.result;
}

const Meta = {
    state: () => ({
        renderedPages: [],
        currentPageIndex: 0,
        trId: localStorage.getItem('trId') || null,
        loading: true,
        direction: 'jump',
        lastCompletedPage: 'intro',
        purchased: false,
        validPages: [],
        tester: false,
        maintenanceMode: false,
    }),
    modules: {
        ...basicPageModules,
        ...incomePageModules,
    },
    mutations: {
        maintenanceMode(state, val) {
            state.maintenanceMode = val;
        },
        tester(state, tester) {
            state.tester = tester;
        },
        clearRenderedPages(state) {
            state.renderedPages = [];
            state.currentPageIndex = 0;
        },
        updateLastCompletedPage(state, page) {
            state.lastCompletedPage = page;
        },
        changeLoading(state, loading) {
            state.loading = loading;
        },
        changeDirection(state, direction) {
            state.direction = direction;
        },
        switchPagesPostRender(state, direction) {
            this.commit('switchPages', direction);
            this.commit('changeLoading', false);
        },
        clearSuccessivePages(state) {
            state.renderedPages = state.renderedPages.slice(
                0,
                state.currentPageIndex + 1,
            );
        },
        addRenderedPage(state, params) {
            if (params.direction == 'next') {
                state.renderedPages.push(params.page);
            } else if (params.direction == 'back') {
                state.renderedPages.unshift(params.page);
                state.currentPageIndex++;
            }
        },
        setTrId(state, newId) {
            state.renderedPages = [];
            state.currentPageIndex = 0;
            state.trId = newId;
            localStorage.setItem('trId', newId);
        },
        switchPages(state, direction) {
            if (direction == 'next') {
                if (!this.getters.needsRendering('next')) {
                    state.currentPageIndex++;
                } else {
                    throw 'next page not rendered';
                }
            } else if (direction == 'back') {
                if (!this.getters.needsRendering('back')) {
                    state.currentPageIndex--;
                } else {
                    throw 'prev page not rendered';
                }
            }
        },
        updateFormsPurchased(state, purchased) {
            state.purchased = purchased;
        },
        validPages(state, validPages) {
            state.validPages = validPages;
        },
    },
    getters: {
        shouldSubmit: (state, getters) => (params) => {
            return getShouldSubmit(
                getters.currentPage,
                getters.lastCompletedPage,
                params.shouldSubmit,
                getters.formsPurchased,
                params.direction,
            );
        },
        maintenanceMode(state) {
            return state.maintenanceMode;
        },
        tester(state) {
            return state.tester;
        },
        validPages(state) {
            return state.validPages;
        },
        formsPurchased(state) {
            return state.purchased;
        },
        lastCompletedPage(state) {
            return state.lastCompletedPage;
        },
        direction(state) {
            return state.direction;
        },
        loading(state) {
            return state.loading;
        },
        getTrId(state) {
            return state.trId;
        },
        currentPage(state) {
            if (!state.renderedPages.length) {
                return null;
            }
            return state.renderedPages[state.currentPageIndex];
        },
        needsRendering: (state) => (direction) => {
            if (direction == 'back') {
                return state.currentPageIndex <= 0;
            } else if (direction == 'next') {
                return state.currentPageIndex >= state.renderedPages.length - 1;
            }
        },
        renderedPages(state) {
            return state.renderedPages;
        },
        compareIndices(state) {
            return [state.currentPageIndex, state.renderedPages.length];
        },
    },
    actions: {
        mountInterview() {
            try {
                return this.dispatch('renderTr')
                    .then(() => {
                        return this.dispatch('renderFirstPage').then((val) => {
                            this.commit('changeLoading', false);
                            return val;
                        });
                    })
                    .catch(() => {});
            } catch (error) {
                this.commit('maintenanceMode', true);
                submitErrorLog(error);
            }
        },
        getIsPurchased(context) {
            try {
                return context.rootGetters.client
                    .get(`is-purchased/${context.getters.getTrId}/`)
                    .then((resp) => {
                        context.commit('updateFormsPurchased', resp.data);
                    });
            } catch (error) {
                this.commit('maintenanceMode', true);
                submitErrorLog(error);
            }
        },
        renderTr(context) {
            try {
                context.commit('updateRedirectingFromInterview', true, {
                    root: true,
                });
                let id = context.getters.getTrId;
                if (id == null) {
                    router.push({ name: 'Dashboard' });
                    context.commit('updateRedirectingFromInterview', false, {
                        root: true,
                    });
                    return new Promise((resolve, reject) => {
                        reject();
                    });
                }
                return context.rootGetters.client
                    .post(`/verify-tr-id/${context.getters.getTrId}/`)
                    .then(() => {
                        this.dispatch('getIsPurchased');
                        context.commit(
                            'updateRedirectingFromInterview',
                            false,
                            {
                                root: true,
                            },
                        );
                    })
                    .catch(() => {
                        router.push({
                            name: 'Dashboard',
                        });
                        context.commit(
                            'updateRedirectingFromInterview',
                            false,
                            {
                                root: true,
                            },
                        );
                        return Promise.reject();
                    });
            } catch (error) {
                this.commit('maintenanceMode', true);
                submitErrorLog(error);
            }
        },
        renderFirstPage(context) {
            try {
                let params = {
                    new_session: 'recent',
                };
                return context.rootGetters.client
                    .get(`/get-page/${context.getters.getTrId}/`, { params })

                    .then((response) => {
                        this.commit('addRenderedPage', {
                            page: response.data.page_name,
                            direction: 'next',
                        });

                        this.commit('validPages', response.data.valid_pages);
                        this.dispatch('addFormData', response.data);
                        this.commit(
                            'updateLastCompletedPage',
                            response.data.last_completed_page,
                        );
                        return response.data.valid_pages;
                    })
                    .catch((error) => {
                        console.dir(error);
                    });
            } catch (error) {
                this.commit('maintenanceMode', true);
                submitErrorLog(error);
            }
        },
        sendPageSubmitEvents(context, params) {
            event('Filing', 'Page Submit', params.currentPage);
            if (params.currentPage == 'income') {
                sendConversion({
                    address: 'AW-417237183/Qnj5CIOO3PcBEL-R-sYB',
                    id: this.getters.getTrId,
                });
            }
        },

        updateLastCompletedPageNoValidate(context, params) {
            this.commit('updateLastCompletedPage', params.newPage);
            context.rootGetters.client.put(
                `/last-completed-page/${this.getters.getTrId}/`,
                {
                    last_completed_page: params.newPage,
                },
            );
            this.dispatch('sendPageSubmitEvents', {
                currentPage: params.currentPage,
            });
        },
        updateLastCompletedPage(context, params) {
            /* params: shouldSubmit, newPage, currentPage, direction */
            console.assert(
                params.shouldSubmit != undefined &&
                    params.newPage != undefined &&
                    params.currentPage != undefined &&
                    params.direction != undefined &&
                    params.direction != 'jump',
            );

            try {
                if (params.shouldSubmit && params.direction == 'next') {
                    this.dispatch('updateLastCompletedPageNoValidate', params);
                }
            } catch (error) {
                this.commit('maintenanceMode', true);
                submitErrorLog(error);
            }
        },
        renderAdjacentPage(context, params) {
            try {
                let requestData = {
                    current_page: this.getters.currentPage,
                    direction: params.direction,
                    handle_checkpoints:
                        params.shouldSubmit && params.direction == 'next',
                };
                return context.rootGetters.client
                    .get(`/get-page/${context.getters.getTrId}/`, {
                        params: requestData,
                    })
                    .then((response) => {
                        this.commit('addRenderedPage', {
                            page: response.data.page_name,
                            direction: params.direction,
                        });
                        this.commit('validPages', response.data.valid_pages);
                        return this.dispatch('addFormData', response.data);
                    })
                    .catch((error) => {
                        submitErrorLog(error);
                    });
            } catch (error) {
                this.commit('maintenanceMode', true);
                submitErrorLog(error);
            }
        },
        goToAdjacentPage(context, params) {
            versioningTimer.runTimersCheck();
            var currentPage = this.getters.currentPage;

            console.assert(
                params.direction != undefined &&
                    params.direction != 'jump' &&
                    params.shouldSubmit != undefined,
            );

            var lastCompletedPageParams = {
                currentPage: currentPage,
                // newPage: newPage,
                shouldSubmit: params.shouldSubmit,
                direction: params.direction,
            };

            this.commit('changeDirection', params.direction);
            if (this.getters.needsRendering(params.direction)) {
                return this.dispatch('renderAdjacentPage', params).then(() => {
                    this.commit('switchPagesPostRender', params.direction);
                    this.commit('changeLoading', false);

                    lastCompletedPageParams.newPage = this.getters.currentPage;

                    return this.dispatch(
                        'updateLastCompletedPage',
                        lastCompletedPageParams,
                    );
                });
            } else {
                this.commit('switchPagesPostRender', params.direction);
                this.commit('changeLoading', false);
                lastCompletedPageParams.newPage = this.getters.currentPage;

                return this.dispatch(
                    'updateLastCompletedPage',
                    lastCompletedPageParams,
                );
            }
        },
        moveWithoutSubmitting(context, params) {
            try {
                var shouldSubmit = this.getters.shouldSubmit({
                    shouldSubmit: params.shouldSubmit,
                    direction: params.direction,
                });

                if (shouldSubmit) {
                    this.commit('clearSuccessivePages');
                }

                this.dispatch('goToAdjacentPage', {
                    direction: params.direction,
                    shouldSubmit: shouldSubmit,
                });
            } catch (error) {
                this.commit('maintenanceMode', true);
                submitErrorLog(error);
            }
        },
        submitAndMoveOn(context, params) {
            try {
                var shouldSubmit = this.getters.shouldSubmit({
                    shouldSubmit: params.shouldSubmit,
                    direction: params.direction,
                });



                if (shouldSubmit) console.log(`should submit: true. page: ${context.getters.currentPage}`);

                if (shouldSubmit) {
                    this.commit('clearSuccessivePages');
                    return params.form
                        .submit(params.direction)
                        .then((status) => {
                            if (status && status.continue == false) {
                                this.commit('changeDirection', 'jump');
                                return;
                            }
                            return this.dispatch('goToAdjacentPage', {
                                direction: params.direction,
                                shouldSubmit: shouldSubmit,
                            });
                        });
                } else {
                    return this.dispatch('goToAdjacentPage', {
                        direction: params.direction,
                        shouldSubmit: shouldSubmit,
                    });
                }
            } catch (error) {
                this.commit('maintenanceMode', true);
                submitErrorLog(error);
            }
        },
        addFormData(context, payload) {
            context.commit(
                `updateFormData_${payload.page_name}`,
                cleanFormData(payload),
            );
        },
        jumpTo(context, jumpTo) {
            try {
                versioningTimer.runTimersCheck();
                this.commit('clearRenderedPages');
                let params = {
                    jump_to: jumpTo,
                    handle_checkpoints: false,
                };
                return context.rootGetters.client
                    .get(`/get-page/${context.getters.getTrId}/`, { params })
                    .then((response) => {
                        this.commit('addRenderedPage', {
                            page: response.data.page_name,
                            direction: 'next',
                        });
                        /* we do not need to update valid_pages here because jump_to never submits */
                        this.dispatch('addFormData', response.data);
                        this.commit('changeLoading', false);
                    });
            } catch (error) {
                submitErrorLog(error);
                this.commit('maintenanceMode', true);
            }
        },
    },
};
export default Meta;
