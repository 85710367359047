<template>
    <div
        :class="getClass"
        :style="getChildStyle"
        class="spinner-border"
    >
        <span class="sr-only">Loading...</span>
    </div>
</template>

<script>
export default {
    props: {
        size: {
            type: Number,
            default: null,
        },
        color: {
            type: String,
            default: '',
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        getClass() {
            var classes = [];
            if (this.color) {
                classes.push(`text-${this.color}`);
            }
            if (this.small) {
                classes.push(`spinner-border-sm`);
            }
            return classes;
        },
        getChildStyle() {
            if (!this.small) {
                return `width:${this.size}px; height:${this.size}px;`;
            }
        },
    },
};
</script>
