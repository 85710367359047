<template>
    <component
        v-if="shouldShow(field.shown_if)"
        @update:data="$emit('input', $event);"
        :is="field.type"
        :field="field"
        :data.sync="data[field.name]"
        @blur="handleBlur(field.name)"
    />
</template>

<script>
import inputs from './inputs';

export default {
    components: inputs,
    props: {
        field: { required: true },
        data: { required: true },
    },
    methods: {
        shouldShow(condition) {
            let shouldShow = true;
            if (condition) {
                eval(condition);
            }
            return shouldShow;
        },
    },
};
</script>
