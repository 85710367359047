<template>
    <div @click="handleTokenWatcher" class="" style="min-height: 100vh">
        <modal class="font-app" v-model="showInactiveModal">
            <template v-slot:title> You've been signed out </template>

            <template v-slot:body>
                You've been inactive for a while and so you've been signed out
                to keep your data safe.

                <br />
                <br />
                Click anywhere on the screen to sign in again.
            </template>
        </modal>

        <modal class="font-app" v-model="newVersionModal">
            <template v-slot:title> Update Available </template>

            <template v-slot:body>
                Please click the "Refresh Page" button below to get the latest
                version of Thriftax.
                <br />
                <br />
                If you have unsaved work, you can click "Close", save your work,
                and then refresh your browser window.
            </template>

            <template v-slot:footer>
                <btn
                    @click="newVersionModal = false"
                    app
                    size="sm"
                    color="info"
                >
                    Close
                </btn>

                <btn @click="refreshPage()" app size="sm"> Refresh Page </btn>
            </template>
        </modal>
        <!--
		<v-row justify="center" :key="`inactive-modal-${showInactiveModal}`">
			<v-dialog v-model="showInactiveModal" width="600px">
				<v-card class="p-3">
					<v-card-title>
						<h2>
							You've been signed out
						</h2>
					</v-card-title>
					<v-card-text>
						<p class="lead p-0 m-0 bs-mb-3">
							You've been inactive for a while and so you've been signed out to
							keep your data safe.

							<br />
							<br />
							Click anywhere on the screen to sign in again.
						</p>
					</v-card-text>
				</v-card>
			</v-dialog>
		</v-row>
		<v-row justify="center" :key="`new-version-modal-${newVersionModal}`">
			<v-dialog v-model="newVersionModal" width="600px">
				<v-card class="p-3">
					<v-card-title>
						<h2>
							Update Available
						</h2>
					</v-card-title>
					<v-card-text>
						<p class="lead p-0 m-0 bs-mb-3">
							Please click the "Refresh Page" button below to get the latest
							version of Thriftax.
							<br />
							<br />
							If you have unsaved work, you can click "Close", save your work,
							and then refresh your browser window.
						</p>
					</v-card-text>
					<v-card-actions>
						<v-btn class="mx-4 mb-4" @click="newVersionModal = false">
							Close
						</v-btn>
						<v-btn color="#ffa51f" class=" mx-4 mb-4" @click="refreshPage()">
							Refresh Page
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>
        -->
        <router-view></router-view>
    </div>
</template>

<script>
import { CLIENT_VERSION } from '@/services/versioning';

export default {
    name: 'App',
    data() {
        return {
            showInactiveModal: false,
            newVersionModal: false,
        };
    },
    watch: {
        inactiveUserModal(val) {
            if (this.newVersionModal) {
                val = false;
            }
            this.showInactiveModal = val;
        },
        newVersionAvailable(val) {
            if (val) {
                this.showInactiveModal = false;
            }
            this.newVersionModal = val;
        },
    },
    mounted() {
        console.log(`Client version: ${CLIENT_VERSION}`);
        if (this.newVersionAvailable) {
            this.newVersionModal = true;
        }
        if (this.$store.getters.isAuthenticated(0)) {
            this.initiateTokenWatcher();
        }
    },
    computed: {
        inactiveUserModal() {
            return this.$store.getters.inactiveUserModal;
        },
        newVersionAvailable() {
            return this.$store.getters.newVersionAvailable;
        },
    },
    methods: {
        refreshPage() {
            window.location.reload();
        },
        initiateTokenWatcher() {
            this.$store.dispatch('initiateTokenWatcher');
        },
        handleTokenWatcher() {
            if (this.$store.getters.inactiveUserModal) {
                this.$store.commit('inactiveUserModal', false);
                this.$router.push({ name: 'Login' });
            }
            if (this.$store.getters.isAuthenticated(0)) {
                this.$store.commit('activeLatestPeriod', true);
            }
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/scss/theme.scss';
</style>

<style>
@import './assets/vendor/font-awesome/css/all.min.css';
@import './assets/css/tel-input.css';
@import './assets/vendor/aos/dist/aos.css';
</style>
