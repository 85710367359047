<template>
    <phone-input
        :validation-rules="field.validation_rules"
        @input="$emit('update:data', $event)"
        :value="data"
        :placeholder="field.placeholder"
        :disabled="$store.getters.formsPurchased"
    >
        <template v-slot:label>
            <dyn-render v-if="field.label" :html="field.label"></dyn-render>
        </template>
    </phone-input>
</template>

<script>
import PhoneInput from '@/components/forms/phone-input';

export default {
    props: {
        data: { required: true },
        field: { type: Object, required: true },
    },
    components: {
        PhoneInput
    },
};
</script>
