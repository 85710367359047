<template>
    <div class="interview-subheader" :class="tooltipFontSizeInherit ? `font-size-inherit` : ``">
        <v-popover trigger="hover" :popoverClass="getClass" :popoverInnerClass="getInnerClass" placement="top" offset="0">
            <div class="link">
                <slot> </slot> <i class="fas fa-info-circle"> </i>
            </div>
            <template slot="popover">
                <slot name="content"> </slot>
            </template>
        </v-popover>
    </div>
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            default: ``,
        },
        tooltipFontSizeInherit: {
            type: Boolean,
            default: false,

        },
        
    },

    computed: {
        getInnerClass() {
            var classes = [];

            classes.push(`tooltip-inner`);

            if (this.size == 'lg') {
                classes.push(`tooltip-inner-lg`);

            }
            return classes;
        },

        getClass() {
            var classes = [];

            classes.push(`tooltip`);

            if (this.size == 'lg') {
                classes.push(`tooltip-lg`);

            }
            return classes;

        },

    },


};
</script>
