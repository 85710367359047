// import NonresidentLanding from "@/views/landings/nonresident_landing";
// import NonresidentLanding2 from "@/views/landings/nonresident_landing_2";
// import PrintForms from "../views/staff/print-forms";
// import SendPasswordReset from "@/views/auth/send-password-reset";
// import ResetPassword from "@/views/auth/reset-password";
// import Login from "../views/auth/login";
// import Signup from "../views/auth/signup";
// import Activate from "../views/auth/activate";
// import Dashboard from "../views/dashboard/returns";
// import Interview from "../views/interview/index";

const routes = [
	{
		path: '',
		name: 'Home',
		component: () => import('../views/landings/home'),
		meta: {
			allowAnonymous: true,
		},
	},
	//{
	//	path: '/landings/nonresident-tax-filing',
	//	name: 'NonresidentLanding',
	//	component: () => import('../views/landings/nonresident_landing'),
	//	meta: {
	//		allowAnonymous: true,
	//		title: 'Thriftax - Nonresident Tax Filing',
	//	},
	//},
	{
		path: '/landings/nonresident-tax-filing-2',
		name: 'NonresidentLanding2',
		component: () => import('../views/landings/nonresident_landing_2'),
		meta: {
			allowAnonymous: true,
			title: 'Thriftax - Nonresident Tax Filing',
		},
	},
	{
		path: '/aboutus',
		name: 'About',
		component: () => import('../views/landings/aboutus'),
		meta: {
			allowAnonymous: true,
			title: 'Thriftax - About Us',
		},
	},
	{
		path: '/contactus',
		name: 'Contact',
		component: () => import('../views/landings/contactus'),
		meta: {
			allowAnonymous: true,
			title: 'Thriftax - Contact Us',
		},
	},
	{
		path: '/privacy-policy',
		name: 'PrivacyPolicy',
		component: () => import('../views/landings/privacy_policy'),
		meta: {
			allowAnonymous: true,
			title: 'Thriftax - Privacy Policy',
		},
	},
	{
		path: '/terms&conditions',
		name: 'TermsAndConditions',
		component: () => import('../views/landings/terms_conditions'),
		meta: {
			allowAnonymous: true,
			title: 'Thriftax - Terms and Conditions',
		},
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/auth/login'),
		meta: {
			allowAnonymous: true,
			title: 'Thriftax - Login',
		},
	},
	{
		path: '/forgot-password',
		name: 'SendPasswordReset',
		component: () => import('../views/auth/send-password-reset'),
		meta: {
			allowAnonymous: true,
			sitemap: { ignoreRoute: true },
		},
	},
	{
		path: '/reset-password/:uid/:token',
		name: 'ResetPassword',
		component: () => import('../views/auth/reset-password'),
		meta: {
			allowAnonymous: true,
			sitemap: { ignoreRoute: true },
		},
	},
	{
		path: '/signup',
		name: 'Signup',
		component: () => import('../views/auth/signup'),
		meta: {
			allowAnonymous: true,
		},
	},
	{
		path: '/activate/:uid/:token/:new_email',
		component: () => import('../views/auth/activate'),
		name: 'Activate',
		meta: {
			allowAnonymous: true,
			sitemap: { ignoreRoute: true },
		},
	},
	{
		path: '/error-test/',
		component: () => import('../views/other/error'),
		name: 'Error',
		meta: {
			allowAnonymous: true,
			sitemap: { ignoreRoute: true },
		},
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: () => import('../views/dashboard/returns'),
	},
	{
		path: '/dashboard/account',
		name: 'Account',
		component: () => import('../views/dashboard/account'),
	},
	{
		path: '/referrals/',
		name: 'Referrals',
		component: () => import('../views/dashboard/referrals'),
	},
	{
		path: '/support/',
		name: 'Support',
		component: () => import('../views/dashboard/support_container'),
	},
	{
		path: '/blog/',
		name: 'BlogHome',
		component: () => import('../views/blog/index'),
		meta: {
			allowAnonymous: true,
			title: 'Thriftax - Blog',
		},
	},
	{
		path: '/faq/',
		name: 'FAQHome',
		component: () => import('../views/landings/faq'),
		meta: {
			allowAnonymous: true,
			title: 'Thriftax - FAQ',
		},
	},
	{
		path: '/dashboard/faq/',
		name: 'FAQDashboard',
		component: () => import('../views/dashboard/faq'),
	},
	{
		path: '/blog/international-filing-and-covid',
		name: 'CovidBlog',
		component: () => import('../views/blog/covid_blog'),
		meta: {
			allowAnonymous: true,
			title: 'Thriftax Blog - International Filing and Covid',
		},
	},
	{
		path: '/blog/filing-as-an-international-student',
		name: 'StudentFilingBlog',
		component: () => import('../views/blog/student_return'),
		meta: {
			allowAnonymous: true,
			title: 'Thriftax Blog - Filing as an International Student',
		},
	},
	{
		path: '/blog/resident-vs-non-resident-alien',
		name: 'ResidentVsNonResidentBlog',
		component: () => import('../views/blog/resident_vs_non_resident'),
		meta: {
			allowAnonymous: true,
			title: 'Thriftax Blog - Resident vs Nonresident Alien',
		},
	},
	{
		path: '/blog/international-student-tax-guide',
		name: 'InternationalStudentGuideBlog',
		component: () =>
			import('../views/blog/international_student_guide_blog'),
		meta: {
			allowAnonymous: true,
			title: 'Thriftax Blog - International Student Tax Guide',
		},
	},
	{
		path: '/staff/print-forms',
		name: 'StaffPrintForms',
		component: () => import('../views/staff/print-forms'),
		meta: {
			requireStaff: true,
			sitemap: { ignoreRoute: true },
		},
	},
	{
		path: '/tax-filing/',
		name: 'Interview',
		component: () => import('../views/interview/index'),
	},
	{
		path: '/additional-purchases/:year/',
		name: 'ExtraPayments',
		component: () => import('../views/other/extra_payments'),
		meta: {
			sitemap: { ignoreRoute: true },
		},
	},
	{
		path: '/doc-parse-mobile/',
		name: 'DocParseMobile',
		component: () => import('../views/interview/doc-parse-mobile'),
		meta: {
            allowAnonymous: true,
			sitemap: { ignoreRoute: true },
		},
	},
	{
		path: '/webinars/',
		name: 'Webinars',
		component: () => import('../views/other/webinars'),
		meta: {
			allowAnonymous: true,
		}
	},
	{
		path: '*',
		name: 'PageNotFound',
		component: () => import('../views/other/page_not_found'),
		meta: {
			allowAnonymous: true,
			sitemap: { ignoreRoute: true },
		},
	},
];

export default routes;
