<template>
    <div class="badge t-badge" :class="getClass">
        <slot>
        </slot>
    </div>
</template>

<script>

export default {
    props: {
        color: {
            type: String,
            default: "success",
        },
    },
    computed: {
        getClass()  {
            var classes = [];

            classes.push(`badge-${this.color}`);
            return classes;
        },
    },

}

</script>


<style scoped>
.t-badge.badge {
    height: min-content;
}
</style>

