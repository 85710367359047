<template>
    <nav class="navbar navbar-expand-lg sticky-top navbar-light bg-white header-shadow">
        <div class="navbar-content">
            <img
                @click="
                    $route.name == 'Home' ? '' : $router.push({ name: 'Home' })
                "
                class="navbar-logo navbar-brand"
                src="@/assets/logo_and_brand.png"
                alt="Logo"
                style="cursor: pointer"
            />
            <button
                @click="active = !active"
                class="navbar-toggler"
                aria-expanded="true"
                :class="{ collapsed: active }"
                type="button"
            >
                <i v-if="active" class="fas fa-times"></i>
                <i v-else class="fa fa-bars"></i>
            </button>
            <div
                class="collapse navbar-collapse"
                :class="{ show: active }"
                id="navbarNav"
            >
                <ul class="navbar-nav">

                    <li v-for="(item, i) in items" :key="item.name" class="nav-item" :class="{'nav-item-last': i == items.length - 1}">
                        <a
                            @click="safeGoto(item.location)"
                            class="nav-link"
                            href="javascript:;"
                            :class="{active: item.location === $route.name}"
                            >{{item.name}}</a
                        >
                    </li>


                    <li class="nav-item nav-btn mr-lg-2">
                        <btn
                            @click="$router.push({ name: 'Signup' })"
                            class="transition-3d-hover nav-btn-content"
                            size="sm"
                        >
                            Get Started
                        </btn>
                    </li>
                    <li class="nav-item nav-btn">
                        <btn
                            @click="$router.push({ name: 'Login' })"
                            class="transition-3d-hover"
                            color="info"
                            size="sm"
                        >
                            Sign In <i class="fas fa-caret-right ml-1"> </i>
                        </btn>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>
<script>
export default {
    data() {
        return {
            active: false,
            items: [
                {
                    location: 'About',
                    name: 'About',
                },
                {
                    location: 'Contact',
                    name: 'Contact',
                },
                {
                    location: 'BlogHome',
                    name: 'Blog',
                },
                {
                    location: 'FAQHome',
                    name: 'FAQ',
                },
            ],
        };
    },
    methods: {
        methodToRunOnSelect(payload) {
            this.object = payload;
        },
        toggleBtn() {
            this.active = !this.active;
            this.event = function (event) {
                let main =
                    event.target.parentNode.parentNode.parentNode
                        .nextElementSibling;

                if (main) {
                    main.addEventListener('click', () => {
                        this.active = false;
                        document.body.removeEventListener('click', this.event);
                    });
                }
            }.bind(this);

            document.body.addEventListener('click', this.event);
        },
    },
    computed: {
        divClasses: function () {
            return {
                active: this.active,
            };
        },
    },
};
</script>
