<template>
    <i
        class="material-icons"
        style="display: inline-flex; vertical-align: middle; align-items: center; font-size: inherit !important; margin-top: -2.5px !important;"
    >
        <slot></slot>
    </i>
</template>

<script>
export default {
};
</script>
