import genVuexInterviewPageModule from '@/store/modules/interview/utilities';
import { PAGE_SEQUENCE } from '@/store/modules/interview/page-sequence-utilities';

const pagesToSkip = new Set(['income']);

var pages = PAGE_SEQUENCE.filter((page) => !pagesToSkip.has(page));

var module = {};

pages.forEach((page) => {
    module[page] = genVuexInterviewPageModule({ pageName: page });
});

export default module;
