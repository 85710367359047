import { unauthClient, apiDefaults, axios } from '@/api/index.js';
import store from '@/store';
import router from '@/router';
var jwt = require('jsonwebtoken');
import submitErrorLog from '@/services/error-logs';

const INACTIVE_PERIOD_MS = 60 * 60 * 1000; // 60 mins

const Auth = {
	state: () => ({
		access: localStorage.getItem('access') || null,
		refresh: localStorage.getItem('refresh') || null,
		unauthClient: unauthClient,
		endpoints: {
			obtain: '/token/',
			refresh: '/token/refresh/',
			register: '/signup/',
			update: '/update/',
			// register: "/accounts/register/",
		},
		email: localStorage.getItem('email') || null,
		activeLatestPeriod: false,
		inactiveUserModal: false,
		initiatedTokenWatcher: false,
		tokenWatcher: null,
	}),
	mutations: {
		tokenWatcher(state, watcher) {
			state.tokenWatcher = watcher;
		},
		clearTokenWatcher(state) {
			clearInterval(state.tokenWatcher);
		},
		initiatedTokenWatcher(state, initiated) {
			state.initiatedTokenWatcher = initiated;
		},
		inactiveUserModal(state, show) {
			state.inactiveUserModal = show;
		},
		activeLatestPeriod(state, active) {
			state.activeLatestPeriod = active;
		},
		updateTokens(state, tokens) {
			var access = tokens.access;
			var refresh = tokens.refresh;
			if (access) {
				localStorage.setItem('access', access);
			} else {
				localStorage.removeItem('access');
			}
			if (refresh) {
				localStorage.setItem('refresh', refresh);
			} else {
				localStorage.removeItem('refresh');
			}
			if (access && refresh) {
				this.dispatch('initiateTokenWatcher');
			}
			state.access = tokens.access;
			state.refresh = tokens.refresh;
		},
		updateAccess(state, access) {
			localStorage.setItem('access', access.access);
			state.access = access.access;
		},
		removeToken(state) {
			localStorage.setItem('access', null);
			state.access = null;
		},
		updateEmail(state, email) {
			localStorage.setItem('email', email);
			state.email = email;
		},
		logout(state) {
			this.commit('updateTokens', {
				access: null,
				refresh: null,
			});
			this.commit('updateEmail', null);
			this.commit('clearTokenWatcher');
		},
	},
	getters: {
		inactiveUserModal(state) {
			return state.inactiveUserModal;
		},
		activeLatestPeriod(state) {
			return state.activeLatestPeriod;
		},
		userEmail(state) {
			return state.email;
		},
		isAuthenticated(state) {
			return (minSecondsLeft) => {
				let refresh = state.refresh;
				if (!refresh) {
					return false;
				}
				var decoded = jwt.decode(refresh);
				var exp = decoded.exp;
				if (Date.now() / 1000 > exp - minSecondsLeft) {
					return false;
				}
				return true;
			};
		},
		client: (state) => {
			try {
				const apiClient = axios.create({
					...apiDefaults,
					headers: {
						Authorization: `Bearer ${state.access}`,
					},
				});
				apiClient.interceptors.request.use((config) => {
					config.headers.Authorization = `Bearer ${state.access}`;
					return config;
				});
				apiClient.interceptors.response.use(
					(response) => {
						// Return a successful response back to the calling service
						return response;
					},
					async (error) => {
						const originalRequest =
							error.config;
						if (
							error.config.url ==
								`/token/refresh/` ||
							(error.response &&
								(error.response
									.message ==
									'Account is disabled.' ||
									error
										.response
										.data
										.detail ==
										'User not found' ||
									error
										.response
										.data
										.detail ==
										'Authentication credentials were not provided.'))
						) {
							store.dispatch(
								'redirectToLogin',
							);
							return Promise.reject(
								error,
							);
						}
						if (
							error.response &&
							error.response
								.status ===
								401 &&
							!originalRequest._retry
						) {
							originalRequest._retry = true;
							let tokens = await apiClient.post(
								`/token/refresh/`,
								{
									refresh: localStorage.getItem(
										'refresh',
									),
								},
							);
							let access_token =
								tokens.data
									.access;
							store.commit(
								'updateAccess',
								{
									access: access_token,
								},
							);
							originalRequest.headers.Authorization = `Bearer ${access_token}`;
							return apiClient(
								originalRequest,
							);
						}
						return Promise.reject(error);
					},
				);

				return apiClient;
			} catch (error) {
				submitErrorLog(error);
			}
		},
	},
	actions: {
		initiateTokenWatcher(context) {
			if (!context.state.initiatedTokenWatcher && !context.state.tokenWatcher) {
				this.commit(
					'tokenWatcher',
					setInterval(() => {
						if (
							!this.getters
								.activeLatestPeriod
						) {
							this.commit(
								'inactiveUserModal',
								true,
							);
							this.commit('logout');
							this.commit(
								'clearTokenWatcher',
							);
						}
						this.commit(
							'activeLatestPeriod',
							false,
						);
					}, INACTIVE_PERIOD_MS),
				);
				this.commit('initiatedTokenWatcher', true);
			}
		},
		redirectToLogin() {
			store.commit('updateRedirectingFromInterview', true);
			router.push({ name: 'Login' });
			store.commit('updateRedirectingFromInterview', false);
		},
		obtainToken(context, authDetails) {
			return context.state.unauthClient
				.post(
					context.state.endpoints.obtain,
					authDetails,
				)
				.then((response) => {
					this.commit('updateTokens', {
						access: response.data.access,
						refresh: response.data.refresh,
					});
					this.commit(
						'updateEmail',
						authDetails.email,
					);
					// this.dispatch('initiateTokenWatcher');
				});
		},
		refreshToken(context) {
			const payload = {
				refresh: context.state.refresh,
			};
			context.state.unauthClient
				.post(context.state.endpoints.refresh, payload)
				.then((response) => {
					this.commit(
						'updateAccess',
						response.data.access,
					);
				});
		},
		registerUser(context, authDetails) {
			return context.state.unauthClient
				.post(
					context.state.endpoints.register,
					authDetails,
				)
				.then((response) => {
					this.commit(
						'updateEmail',
						authDetails.email,
					);
				});
		},
		sendPasswordReset(context, email) {
			this.commit('logout');
			return context.state.unauthClient.post(
				'/reset-password/',
				{
					email: email,
				},
			);
		},
		resetPassword(context, payload) {
			return context.state.unauthClient
				.post('/reset-password/', payload)
				.then((resp) => {
					this.commit(
						'updateEmail',
						resp.data.email,
					);
					return resp;
				});
		},
		updateUserEmail(context, authDetails) {
			return context.state.unauthClient.post(
				context.state.endpoints.update,
				authDetails,
			);
		},
	},
};
export default Auth;
