import { CLIENT_VERSION } from "@/services/versioning";
import router from "@/router";
import { unauthClient } from "../api";
import store from "@/store";

function objToString(obj) {
  return Object.entries(obj).reduce((str, [p, val]) => {
    console.log(typeof val)
    console.log((typeof val) == "object")
    console.log((typeof val) === "object")
    console.log(val !== null);
    if (val.stack) {
      val = val.stack;
    }
    else if ((typeof val) == "object" && val !== null) {
      console.log(typeof val.stack);
      console.log(val);
      val = objToString(val);
      console.log(val);
    }

    return `${str}${p}::${val}\n`;
  }, "");
}

function simpleStringify(object) {
  if (object.vm) {
    delete object.vm;
  }
  return objToString(object);
}

function getBrowserType() {
  var val = null;

  var isOpera =
    (!!window.opr && !!opr.addons) ||
    !!window.opera ||
    navigator.userAgent.indexOf(" OPR/") >= 0;

  // Firefox 1.0+
  var isFirefox = typeof InstallTrigger !== "undefined";

  // Safari 3.0+ "[object HTMLElementConstructor]"
  var isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function(p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && window["safari"].pushNotification)
    );

  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;

  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;

  // Chrome 1 - 79
  var isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  // Edge (based on chromium) detection
  var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;

  // Blink engine detection
  var isBlink = (isChrome || isOpera) && !!window.CSS;

  if (isOpera) {
    val = "opera";
  } else if (isFirefox) {
    val = "firefox";
  } else if (isSafari) {
    val = "safari";
  } else if (isIE) {
    val = "IE";
  } else if (isEdge) {
    val = "edge";
  } else if (isChrome) {
    val = "chrome";
  } else if (isEdgeChromium) {
    val = "edge chromium";
  } else if (isBlink) {
    val = "blink";
  }
  return val;
}

const ENABLED =
   window.location.hostname != "localhost" &&
   window.location.hostname != "127.0.0.1";
// const ENABLED = true;

async function submitErrorLog(error) {
  try {
    try {
      if (ENABLED) {
        console.log(error);
        var obj = {};
        delete error.vm;
        obj = error;
        
        // obj.message = error.err.message;
        // obj.stack = error.err.stack;
        // obj.err = error.err;
        // obj.info = error.info;
        error = simpleStringify(obj);
      }
    } catch (e) {
      console.log(e);
    }
    try {
      var requestData = {
        frontend_error: error,
        tr_id: store.getters.getTrId,
        forms_purchased: store.getters.formsPurchased,
        last_completed_page: store.getters.lastCompletedPage,
        direction: store.getters.direction,
        current_page: store.getters.currentPage,
        needs_rendering: store.getters.needs_rendering,
        rendered_pages: store.getters.renderedPages,
        client_version: CLIENT_VERSION,
        browser_type: getBrowserType(),
        host: window.location.hostname
      };
    } catch {
      var requestData = {};
    }
    try {
      requestData["route"] = router.currentRoute.fullPath;
    } catch {}
    try {
      requestData.currentPageData =
        store.getters[`${store.getters.currentPage}Data`];
    } catch {}
    try {
      requestData.lastCompletedPageData =
        store.getters[`${store.getters.lastCompletedPage}Data`];
    } catch {}
    if (ENABLED) {
      await unauthClient.post("/client-error-log/", requestData);
    } else {
      throw error;
    }
  } catch (error) {
    var requestData = { error };
    try {
      requestData["route"] = router.currentRoute.fullPath;
    } catch {}
    if (ENABLED) {
      await unauthClient.post("/client-error-log/", requestData);
    } else {
      throw error;
    }
  }
}

export default submitErrorLog;

import Vue from "vue";
if (ENABLED) {
  Vue.config.errorHandler = (err, vm, info) => {
    var error = {
      err,
      vm,
      info
    };
    submitErrorLog(error);
  };
}
