const PAGE_SEQUENCE = [
	// used to see if we should update last_completed_page
	"intro",
	"is_US_citizen",
	"has_green_card",
	"applied_for_green_card",
	"green_card_application_status",
	"had_US_income",
	"is_married",
	"has_dependents",
	"is_dependent",
	"is_US_scholar",
	"paid_interest_on_student_loan",
	"gave_charity",
	"filed_taxes_prev_year",
	"filed_state_taxes_prev_year",
	"current_visa",
	"previous_visas",
	"previous_country",
	"personal_info",
	"filed_taxes_any_prev_year",
	"non_recent_return",	
	"home_US",
	"home_abroad",
	"marital_status",
	"marital_status_2",
	"dependents",
	"letter_6419", 
	"school",
	"income",
	"fica",
	"university_expenses",
	"clarify_treaties",
	"treaties",
	"received_1095A",
	"independent_expenses",
	"charity_expenses",
	"loan_expenses",
	"add_info",
	"previous_return_info",
	"state_visits",
	"california_residency",
	"state_return_unneeded",
	"california_has_fincome",
	"california_fincome",
	"california_nr",
	"california_nr_spouse",
	"california_had_out_of_state_purchases",
	"california_out_of_state_purchases",
	"california_r",
	"cali_time_of_income",
	"due_info",
	"refund_info",
	"no_diff_return",
	"review_federal",
	"review_fica",
	"review_cali",
	"corresp_location",
	"is_shipping_8843",
	"checkout_options",
	"checkout",
	"signature_8843",
	"gen_pdfs",
	"shipping_info",
];

function isOrdered(page1, page2) {
	if (page1 == page2) {
		return {
			same: true,
			result: false,
		}
	}
	let found1 = false;
	for (let i = 0; i < PAGE_SEQUENCE.length; i++) {
		if (PAGE_SEQUENCE[i] == page1) {
			found1 = true;
		} else if (PAGE_SEQUENCE[i] == page2) {
			if (found1) {
				return {
					same: false,
					result: true,
				};
			}
			return {
				same: false,
				result: false,
			};
		}
	}
	return {
		same: false,
		result: false,
	};
}

export default isOrdered;
 
export {PAGE_SEQUENCE};
