<template>
  <validation-provider
    ref="validator"
    :rules="validationRules"
    v-slot="{ errors }"
  >
    <div class="input-icons">
      <label class="input-label input-label-spacing">
        <slot name="label"> </slot>
      </label>
      <select
        class="form-control custom-field my-0 py-0"
        :class="getClass(errors.length)"
        @input="
          $emit('input', $event.target.value);
          $emit('update:value', $event.target.value);
        "
        :value="value"
        :id="id"
        :disabled="disabled"
        @blur="$emit('blur')"
      >
        <option v-for="item in choices" :value="item[0]" :key="item[0]">
          {{ item[1] }}
        </option>
      </select>
      <span class="input-icon-append">
        <i class="fas fa-caret-down"></i>
      </span>
      <span class="row flex-row my-0 mx-1 text-danger small">{{
        getError(errors)
      }}</span>
    </div>
  </validation-provider>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number]
    },
    validationRules: {
      type: String,
      default: ``
    },
    placeholder: {
      type: String
    },
    inputClass: {
      type: String,
      default: ``
    },
    inputType: {
      type: String,
      default: `text`
    },
    dataMask: {
      type: String,
      default: ``
    },
    disabled: {
      type: Boolean,
      default: false
    },
    addErrors: {
      type: Array,
      default: () => {
        return [];
      }
    },
    choices: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  methods: {
    getClass(hasErrors) {
      var classes = [];

      if (this.addErrors.length || hasErrors) classes.push(`is-invalid`);

      classes.push(this.inputClass);

      return classes;
    },
    getError(validatorErrors) {
      if (validatorErrors.length) {
        return validatorErrors[0];
      } else if (this.addErrors.length) {
        return this.addErrors[0];
      }
      return ``;
    }
  }
};
</script>
