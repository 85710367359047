<template>
  <div class="row m-0 p-0" :class="getRowClass">
    <template v-for="(field, i) in schema">
      <div
        class="col p-0 m-0"
        :class="getClass(i)"
        v-if="field && shouldShow(field.shown_if)"
        :md="getCols(i)"
        :cols="12"
        v-bind:key="field[0]"
      >
        <component
          class="w-100"
          @update:data="emitChange($event, field.name)"
          :is="field.type"
          :field="field"
          :data.sync="data[field.name]"
          @blur="handleBlur(field.name)"
          :fill-top="fillTop"
        ></component>
      </div>
    </template>
  </div>
</template>

<script>
import inputs from "./inputs";

export default {
  components: inputs,
  props: {
    schema: { required: true },
    data: { required: true },
    fillTop: {
      default: true,
      type: Boolean
    },
    align: {
      default: "start",
      type: String
    },
    cols: {
      type: Array,
      default() {
        return [];
      }
    },
    rowBreakpoint: {
      type: String,
      default: "md"
    },
    noMarginBottom: {
      type: Boolean,
      default: false
    },
    alignVertical: {
      type: String,
      default: null
    }
  },
  computed: {
    getRowClass() {
      var classes = [];
      classes.push(`justify-content-${this.align}`);
      return classes;
    }
  },
  methods: {
    emitChange(newVal, fieldName) {
      var newData = this.data;
      newData[fieldName] = newVal;
      var eventD = {
        field: fieldName,
        val: newVal,
        data: newData,
      };
      this.$emit("change", eventD);
    },
    shouldShow(condition) {
      let shouldShow = true;
      if (condition) {
        eval(condition);
      }
      return shouldShow;
    },
    getCols(index) {
      if (this.cols.length && index < this.cols.length) {
        return this.cols[index];
      }
      return "";
    },
    handleBlur(fieldName) {
      var eventD = {
        field: fieldName,
        val: this.data[fieldName]
      };
      this.$emit("blur", eventD);
    },
    getClass(i) {
      var classes = [];

      if (this.schema.length > 1) {
        if (i === 0) {
          classes.push(`fly-row-col-pr-${this.rowBreakpoint}`);
        } else if (i === this.schema.length - 1) {
          classes.push(`fly-row-col-pl-${this.rowBreakpoint}`);
        } else {
          classes.push(`fly-row-col-px-${this.rowBreakpoint}`);
        }
      }

      if (this.smallMargins) {
        classes.push(`mb-0 pb-0 custom_small_col`);
      }
      if (this.noMargins) classes.push(`naked_col`);

      var medCols = this.getCols(i);

      if (medCols) {
        classes.push(`col-${this.rowBreakpoint}-${medCols}`);
      } else {
        classes.push(`col-${this.rowBreakpoint}`);
      }

      classes.push(`col-12`);

      if (!this.noMarginBottom) {
        classes.push(`input-margin-bottom`);
      }

      if (this.alignVertical) {
        classes.push(`d-flex align-items-${this.alignVertical}`);
      }

      return classes;
    }
  }
};
</script>

<style scoped>
/*
.custom-col {
    padding-left: 7px !important;
    padding-right: 7px !important;
}
*/
.custom_small_col {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.naked_col {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
