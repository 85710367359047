function genVuexInterviewPageModule(options) {
	var pageName = options.pageName;
	var module = {
		state: {
			sections: [],
			formsets: {},
			context: {},
			backendErrors: [],
		},
		mutations: {},
		getters: {},
	};

	module.mutations[`updateFormData_${pageName}`] = (state, payload) => {
		state.sections = payload.sections;
		state.context = payload.context;

		if (payload.formsets) {
			for (var formsetName in payload.formsets) {
				var formset = payload.formsets[formsetName];
				formset.forEach((item) => {
					item.errors = [];
				});
				state.formsets[formsetName] = state.formsets[
					formsetName
				] = JSON.parse(JSON.stringify(formset));
			}
		}
	};

	module.mutations[`updateFormDataFromVue_${pageName}`] = (
		state,
		params,
	) => {
		state.sections = params.sections;

		if (params.formsets) {
			for (var formsetName in params.formsets) {
				state.formsets[formsetName] =
					params.formsets[formsetName];
			}
		}
	};

	module.mutations[`clearErrors_${pageName}`] = (state) => {
		state.backendErrors = [];
	};

	module.mutations[`addError_${pageName}`] = (state, error) => {
        state.backendErrors.push(error);
	};

	module.mutations[`updateContext_${pageName}`] = (
		state,
		context,
	) => {
		state.context = context;
	};

	module.getters[`${pageName}Data`] = (state) => {
		return {
			sections: state.sections,
			context: state.context,
			formsets: state.formsets,
			errors: state.backendErrors,
		};
	};

	module.getters[`backendErrors_${pageName}`] = (state) => {
		return state.backendErrors;
	};

	return module;
}

export default genVuexInterviewPageModule;
