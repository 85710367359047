import cleanFormData from '@/api/clean-form-data';
import submitErrorLog from '@/services/error-logs';


export default {
    state: {
        docs: [],
        context: {},
        editDoc: {},
        editDocId: null,
        loading: true,
        currDocCompleted: null,
    },
    mutations: {
        // all other modules designed for pages should be of the format: updateFormData_${name of the page}
        updateFormData_income(state, payload) {
            state.context = payload.context;
            state.docs = payload.docs;
        },
        addIncomeDoc(state, doc) {
            state.docs.push(doc);
            state.editDocId = doc.id;
        },
        removeIncomeDoc(state, id) {
            state.docs = state.docs.filter((doc) => {
                return doc.id != id;
            });
        },
        updateEditDoc(state, data) {
            state.editDoc = data;
        },
        updateEditDocId(state, id) {
            state.editDocId = id;
        },
        updateDocInDocs(state, docInfo) {
            for (let i = 0; i < state.docs.length; i++) {
                if (state.docs[i].id == docInfo.id) {
                    if ('payer_name' in docInfo) {
                        state.docs[i].payer_name = docInfo.payer_name;
                    }
                    if ('is_completed' in docInfo) {
                        state.docs[i].is_completed = docInfo.is_completed;
                    }
                }
            }
        },
        updateLoading(state, loading) {
            state.loading = loading;
        },
        currDocCompleted(state, val) {
            state.currDocCompleted = val;
        },
    },
    getters: {
        currDocCompleted(state) {
            return state.currDocCompleted;
        },

        incomeDocs(state) {
            return state.docs;
        },
        incomeDocsContext(state) {
            return state.context;
        },
        editDoc(state) {
            return state.editDoc;
        },
        editDocId(state) {
            return state.editDocId;
        },
        editDocLoading(state) {
            return state.loading;
        },
    },
    actions: {
        createDoc(context, type) {
            try {
                let requestData = {
                    tr: context.rootGetters.getTrId,
                    type: type,
                };
                this.commit('updateLastCompletedPage', 'income', {
                    root: true,
                });
                return context.rootGetters.client
                    .post('income-doc/', requestData)
                    .then((response) => {
                        this.commit('addIncomeDoc', response.data);
                    });
            } catch (error) {
                this.commit('maintenanceMode', true);
                submitErrorLog(error);
            }
        },
        deleteDoc(context, id) {
            try {
                this.commit('updateLastCompletedPage', 'income', {
                    root: true,
                });
                return context.rootGetters.client
                    .delete(`income-doc/${id}/`)
                    .then(this.commit('removeIncomeDoc', id));
            } catch (error) {
                this.commit('maintenanceMode', true);
                submitErrorLog(error);
            }
        },
        updateEditDoc(context, id) {
            this.commit('updateLoading', true);
            this.commit('updateEditDocId', id);
            return context.rootGetters.client
                .get(`income-doc/${id}/`)
                .then((response) => {
                    console.log(response);
                    this.commit('updateEditDoc', cleanFormData(response.data));
                    this.commit('updateLoading', false);
                    this.commit('currDocCompleted', response.data.is_completed);
                });
        },
        submitIncomeDoc(context, docData) {
            try {
                this.commit('updateLastCompletedPage', 'income', {
                    root: true,
                });

                var displayName = '';

                if (docData.payload.payer_name) {
                    displayName = docData.payload.payer_name;
                } else if (docData.payload.S1042_withholding_agent_name) {
                    displayName = docData.payload.S1042_withholding_agent_name;
                }

                return context.rootGetters.client
                    .put(`income-doc/${docData.id}/`, docData.payload)
                    .then((response) => {
                        this.commit('updateDocInDocs', {
                            id: docData.id,
                            is_completed: response.data.is_completed,
                            payer_name: displayName,
                        });
                    })
                    .catch((error) => {
                        this.commit('maintenanceMode', true);
                        submitErrorLog(error);
                    });
            } catch (error) {
                this.commit('maintenanceMode', true);
                submitErrorLog(error);
            }
        },
    },
};
