<template>
    <text-input
        :data-mask="field.data_mask"
        :placeholder="field.placeholder"
        :id="getInputId(field)"
        :value="data"
        @blur="$emit('blur')"
        @input="handleInput($event)"
        :disabled="$store.getters.formsPurchased"
        :validation-rules="validationRules"
    >
        <template v-slot:label>
            <dyn-render v-if="field.label" :html="field.label"></dyn-render>
            <span v-else-if="fillTop">&shy;</span>
        </template>
    </text-input>
</template>

<script>
import { numberInputMixin } from './utilities';

export default {
    mixins: [numberInputMixin],
    mounted() {
        if (this.field.comma_separators == false) {
            this.commaSeparators = false;
        }
    },
    data() {
        return {
            commaSeparators: true,
        };
    },
    props: ['field', 'data'],
    computed: {
        validationRules() {
            if (this.field.validation_rules) {
                return `integer|${this.field.validation_rules}`;
            }
            return 'integer';
        },
    },
};
</script>
