<template>
    <text-input
        :data-mask="field.data_mask"
        :placeholder="field.placeholder"
        :id="getInputId(field)"
        :value="data"
        @blur="$emit('blur')"
        @input="handleInput($event)"
        :disabled="$store.getters.formsPurchased || field.disabled"
        :validation-rules="validationRules"
    >
        <template v-slot:label>
            <dyn-render v-if="field.label" :html="field.label"></dyn-render>
            <span v-else-if="fillTop">&shy;</span>
        </template>
    </text-input>
</template>

<script>
import { numberInputMixin } from './utilities';
export default {
    mixins: [numberInputMixin],
    props: {
        field: Object,
        data: [String, Number],
        fillTop: {
            type: Boolean,
            default: false,
        },
        showDollarSign: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        validationRules() {
            if (this.field.validation_rules) {
                return `number|${this.field.validation_rules}`;
            }
            return 'number';
        },
    },
};
</script>
