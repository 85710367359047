<template>
    <div class="t-modal">
        <div
            class="modal-dialog modal-dialogue-centered"
            :class="getModalDialogueClass"
        >
            <transition name="fade">
                <div
                    v-if="value"
                    class="modal-content"
                    tabindex="0"
                    v-click-outside="onClickOutside" >
                    <div v-if="!!this.$slots.title" class="modal-header mb-4">
                        <h3 class="modal-title" id="exampleModalLabel">
                            <slot name="title">Modal Title</slot>
                        </h3>

                        <button
                            @click="closeModal"
                            type="button"
                            class="btn btn-xs btn-icon btn-soft-secondary"
                        >
                            <svg
                                width="10"
                                height="10"
                                viewBox="0 0 18 18"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill="currentColor"
                                    d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"
                                />
                            </svg>
                        </button>
                    </div>
                    <div v-else class="d-flex justify-content-end pt-2 px-2 pb-1">
                        <btn
                            @click="closeModal"
                            size="xs"
                            class="btn-xs btn-icon"
                            app
                            color="info"
                        >
                            <i class="fas fa-times"></i>
                        </btn>
                    </div>
                    <div class="modal-body mt-0 pt-0">
                        <slot name="body"> Modal body </slot>
                    </div>
                    <div  class="modal-footer px-2 py-2" v-if="footer">
                        <slot name="footer">
                            <btn
                                app
                                @click="closeModal"
                                color="secondary"
                                size="sm"
                            >
                                Close
                            </btn>
                        </slot>
                    </div>
                </div>
            </transition>
        </div>
        <transition name="fade">
            <div v-if="value" class="modal-overlay"></div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        size: {
            type: String,
            default: null,
        },
        clickOutside: {
            type: Boolean,
            default: true,
        },
        footer: {
            type: Boolean,
            default: true,
        }
    },
    methods: {
        onClickOutside() {
            if (this.clickOutside) this.closeModal();
        },
        closeModal() {
            if (this.value) this.$emit('input', false);
        },
    },
    computed: {
        getModalDialogueClass() {
            if (!this.size) return;
            if (!['sm', 'lg', 'xl'].includes(this.size)) {
                throw `Valid values of prop size are 'sm', 'lg', 'xl'`;
            }

            return `modal-${this.size}`;
        },
    },
};
</script>

<style lang="scss"></style>
