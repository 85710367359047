<template>
	<validation-provider ref="validator" :rules="field.validation_rules" v-slot="{ errors }">
		<div class="js-form-message my-0 py-0">
			<div class="d-flex flex-row my-0 py-0">
				<label
					v-if="field.label"
					v-html="field.label"
					class="input-label mb-0 ml-1"
				></label>
				<label
					v-else-if="shouldFillTop"
					class="input-label mb-0 ml-1"
				>
					<span>&shy;</span>
				</label>
			</div>
			<v-select
				:disabled="isPurchased"
				class="search-select"
				:class="{disabled: isPurchased}"
				v-model="item"
				:options="options"
				@input="makeDirty()"
			>
			</v-select>
		</div>
		<span class="row flex-row my-0 py-0 ml-1 text-danger small">{{
			errors[0]
		}}</span>
	</validation-provider>
</template>

<script>
import "vue-search-select/dist/VueSearchSelect.css";
import { ModelSelect } from "vue-search-select";

export default {
	props: ["field", "data", "fillTop", "show"],
	data() {
		return {
			currentOption: "",
			options: [],
			item: {
				value: "",
				text: "",
			},
		};
	},
	created() {
		this.field.choices.forEach((choice) => {
			let choiceObj = {
				text: choice,
				value: choice,
			};
			this.options.push(choiceObj);
		});
		this.item.value = this.item.text = this.data;
		this.$watch('data', (val) => {
			this.item.value = this.item.text = val;
		})
	},
	components: {
		vSelect: ModelSelect,
	},
	methods: {
		makeDirty() {
			this.$refs.validator.setFlags({ touched: true, dirty: true });
		},
		reset() {
			this.item = {};
		},
		selectFromParentComponent1() {
			// select option from parent component
			this.item = this.options[0];
		},
	},
	watch: {
		item(newVal) {
			this.$emit("update:data", newVal.value);
		},
		value: function(newValue) {
			this.currentOption = newValue;
		},
		show: function() {
			this.data1 = "";
		},
	},
	computed: {
		isPurchased() {
			return this.$store.getters.formsPurchased;
		},
		shouldFillTop() {
			if (this.fillTop == true || this.fillTop == "true") {
				return true;
			}
			return false;
		},
	},
};
</script>

<style>
input.search {
	background-color: red;
	padding-top: 13px !important;
}
.dropdown.icon  {
	padding-top: 15px !important;
}

.search-select {
	padding-top: 15px !important;
	padding-bottom: 0px !important;
	margin-top: 0px !important;
	margin-bottom: 0px !important;
	height: 47px !important;
}
.search-select.disabled {
	background-color: #eaf1fa !important;
}
</style>
