function sendConversion(params) {
	var address = params.address;

	var scriptElm = document.createElement("script");

	if (params.id != null) {
		var inlineCode = document.createTextNode(
			`gtag("event", "conversion", { 'send_to': '${address}', 'transaction_id': '${params.id}' });`
		);
	} else {
		var inlineCode = document.createTextNode(
			`gtag("event", "conversion", { 'send_to': '${address}' });`
		);
	}
	scriptElm.appendChild(inlineCode);
	document.head.appendChild(scriptElm);
}

export default sendConversion;
