<template>
    <validation-provider
        :rules="field.validation_rules"
        class="w-100"
        v-slot="{ errors }"
        ref="validator"
    >
        <div class="row m-0 d-flex justify-content-center">
            <div
                v-for="(item, index) in field.choice_schema.slice()"
                :key="item.value"
                class="col d-flex justify-content-center chunky-radio p-0"
                :class="colClass"
            >
                <input
                    :value="item.value"
                    v-model="internalVal"
                    @input="$emit('update:data', $event.target.value)"
                    :name="field.name"
                    :id="getId(index)"
                    type="radio"
                    :disabled="$store.getters.formsPurchased"
                />
                <div class="chunky-radio-label-wrapper">
                    <label :for="getId(index)">
                        <div>
                            <div
                                class="chunky-radio-icon"
                                :class="`chunky-radio-${getIconColor(item)}`"
                            >
                                <i class="fas fa-2x" :class="item.icon"></i>
                            </div>
                            <div class="chunky-radio-text">{{ item.text }}</div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    </validation-provider>
</template>

<script>
export default {
    props: {
        data: {
            required: true,
        },
        field: {
            type: Object,
            required: true,
        },
    },
    computed: {
        colClass() {
            var classes = [];
            var len = this.field.choice_schema.length;
            if (len < 3) {
                classes.push(`col-xl-4 col-lg-4 col-md-4`);
            } else throw `not implemented`;
            if (this.field.size) {
                classes.push(`radio-${this.field.size}`);
            }
            return classes;
        },
    },
    created() {
        this.internalVal = this.data;
    },
    data() {
        return {
            internalVal: '',
        };
    },
    methods: {
        getId(index) {
            return `${this.getInputId(this.field)}-${index}`;
        },
        updateValue(val) {
            this.$emit('update:data', val);
        },
        getIconColor(option) {
            return option.color ? option.color : `primary`;
        },
    },

    props: ['data', 'field'],
};
</script>
