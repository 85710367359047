<template>
    <label class="icon-checkbox pt-2">
        <h5 class="text-danger pt-1 pb-0 mb-0">
            <i class="fas fa-trash-alt"></i>
            <slot> </slot>
        </h5>

        <input
            type="checkbox"
            :checked="value"
            :disabled="isPurchased"
            @change="$emit('input', $event.target.checked)"
            @click="$emit('click')"
        />
    </label>
</template>

<script>
export default {
    computed: {
        isPurchased() {
            return this.$store.getters.formsPurchased;
        },
    },
    props: ['value'],
};
</script>
