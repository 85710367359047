<template>
    <validation-provider ref="validator">
        <span class="custom-control custom-checkbox">
            <input
                type="checkbox"
                :value="value"
                :checked="value"
                @input="$emit('input', $event.target.checked);"
                :id="id"
                :disabled="disabled"
                class="custom-control-input"
            />
            <label class="custom-control-label" :for="id"><slot name="label"></slot></label>
        </span>
    </validation-provider>
</template>

<script>
export default {
    props: {
        value: {
            required: true,
        },
        id: {
            type: String,
            default: null,
        },
        validationRules: {
            type: String,
            default: ``,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

