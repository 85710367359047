import axios from "axios";

const BASE_URL = "https://www.thriftax.com/API"; // this is replaced with dist base url on build-frontend command

console.log("Base URL: ", BASE_URL);

const apiDefaults = {
  baseURL: BASE_URL,
  timeout: 25000,
  headers: {
    "Cache-Control": null,
    "X-Requested-With": null
  }
};

const unauthClient = axios.create({
  baseURL: BASE_URL,
  timeout: 30000
});

export { unauthClient, apiDefaults, axios, BASE_URL };
