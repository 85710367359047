<template>
    <validation-provider
        ref="validator"
        :rules="validationRules"
        v-slot="{ errors }"
        @click="$emit('click')"
        class="t-phone"
    >
        <label class="input-label input-label-spacing">
            <slot name="label"> </slot>
        </label>
        <vue-tel-input
            :class="getClass(errors.length)"
            v-model="internalVal"
            :placeholder="placeholder"
            ref="input"
            :disabled="disabled"
            :value="internalVal"
            :inputOptions="inputOptions"
            :id="id"
            @input="handleInput($event)"
            @country-changed="handleCountryChange()"
            :defaultCountry="defaultCountry"
        ></vue-tel-input>
        <span class="row flex-row my-0 mx-1 text-danger small">{{
            getError(errors)
        }}</span>
    </validation-provider>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';
export default {
    components: {
        VueTelInput,
    },
	data() {
		return {
			internalVal: null,
			initInput: true,
			defaultCountry: "US",
		};
	},
    props: {
        id: {
            type: String,
            default: null,
        },
        value: {
            type: String,
        },
        validationRules: {
            type: String,
            default: ``,
        },
        placeholder: {
            type: String,
        },
        inputClass: {
            type: String,
            default: ``,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        addErrors: {
            type: Array,
            default: () => {
                return [];
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
	created() {
		var phoneObj = this.fullValToShortVal(this.value);
		if (phoneObj.iso2) this.defaultCountry = phoneObj.iso2;
		this.internalVal = phoneObj.cleanVal;
		this.$watch('value', (val) => {
			this.internalVal = this.fullValToShortVal(val).cleanVal;
		});
	},
	mounted() {
		this.$nextTick(() => {
			this.cleanValidator();
		});
	},
    computed: {
        inputOptions() {
            return {
                readonly: this.disabled,
            };
        },
		telInputMounted() {
			return this.$refs.input.finishMounted;
		},
    },
    methods: {
        fullValToShortVal(fullVal) {
            var cleanVal = '';
            var iso2 = null;
            if (fullVal && fullVal.includes('{')) {
                var closingBraceFound = false;
                iso2 = '';
                for (var char of fullVal) {
                    if (char == '}') closingBraceFound = true;
                    else if (closingBraceFound)
                        cleanVal = cleanVal.concat(char);
                    else if (char.match(/[A-Z]/i)) iso2 = iso2.concat(char);
                }
            } else cleanVal = fullVal;

            return { cleanVal, iso2 };
        },
        handleInput(number) {
            number = number.replace('{', '');
            number = number.replace('}', '');
            this.$nextTick(() => {
                this.internalVal = number;
            });
            if (this.initInput) {
                this.cleanValidator();
            }
            var fullVal = `{${this.$refs.input.phoneObject.country.iso2}}${number}`;
            this.$emit('input', fullVal);
        },
        cleanValidator() {
            this.$refs.validator.reset();
        },
        handleCountryChange() {
            if (this.isPurchased) {
                this.cleanValidator();
            }
        },
        getClass(hasErrors) {
            var classes = [];
            if (this.addErrors.length || hasErrors) classes.push(`phone-is-invalid`);
            if (this.disabled) classes.push(`disabled-phone`);
            classes.push(this.inputClass);
            return classes;
        },
        getError(validatorErrors) {
            if (validatorErrors.length) {
                return validatorErrors[0];
            } else if (this.addErrors.length) {
                return this.addErrors[0];
            }
            return ``;
        },
    },
};
</script>
