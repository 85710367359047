<template>
    <div class="w-100 position-relative">
        <template v-for="field in schema">
            <div
                v-if="field && shouldShow(field.shown_if)"
                :key="field.name"
                class="input-margin-bottom"
            >
                <component
                    @update:data="emitChange($event, field.name)"
                    v-bind:key="field.name"
                    :is="field.type"
                    :field="field"
                    :data.sync="data[field.name]"
                    :show="show"
                    :param="param"
                    :param2="param2"
                    :prop1="prop1"
                    :v-if="field.shown_if"
                ></component>
            </div>
        </template>
    </div>
</template>

<script>
import inputs from './inputs';

export default {
    components: inputs,
    props: {
        schema: { required: true },
        data: { required: true },
        show: { required: false },
        param: { required: false },
        param2: { required: false },
        param3: { required: false },
        prop1: { required: false },
        margin: {
            default: '3',
            type: String,
        },
        align: {
            default: 'start',
            type: String,
        },
        rowOnLg: {
            default: false,
            type: Boolean,
        },
    },
    methods: {
        shouldShow(condition) {
            let shouldShow = true;
            if (condition) {
                eval(condition);
            }
            return shouldShow;
        },
        emitChange(newVal, fieldName) {
            var newData = this.data;
            newData[fieldName] = newVal;
            var eventD = {
                id: this.schema[0].id,
                field: fieldName,
                val: newVal,
                data: newData,
            };
            this.$emit('change', eventD);
        },
    },
    computed: {
    },
};
</script>
