import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {
    ValidationObserver,
    ValidationProvider,
} from './plugins/v-validate.js';
import VueMask from 'v-mask';
import LoadScript from 'vue-plugin-load-script';
import { VueWindowSizePlugin } from 'vue-window-size/option-api';
import VueYoutube from 'vue-youtube';
import VueSignaturePad from 'vue-signature-pad';
import VueSmoothScroll from 'vue2-smooth-scroll';
import VueAnalytics from 'vue-analytics';
import '@/components';
import vClickOutside from 'v-click-outside';
import 'material-icons/iconfont/material-icons.css';
import Toasted from 'vue-toasted';
import { formatDollarAmount } from '@/views/utilities';
import VTooltip from 'v-tooltip';

Vue.use(vClickOutside);
Vue.use(VueYoutube);
Vue.use(VueAnalytics, { id: 'UA-188846824-1', router });
Vue.use(VueSmoothScroll);
Vue.use(VueSignaturePad);
Vue.use(VueWindowSizePlugin);
Vue.use(LoadScript);
Vue.use(Toasted);
Vue.use(VueMask);
Vue.use(VTooltip);

Vue.mixin({
    methods: {
        formatDollarAmount(x) {
            return formatDollarAmount(x);
        },
        boolEq(boolVal, formField) {
            /* boolean radio selects may have a value of true or
             * 'true' depending on if they were just rendered. add
             * universal way to compare these boolean radio selects
             * with boolean literals */
            if (boolVal === true)
                return formField === true || formField === 'true';
            else if (boolVal === false)
                return formField === false || formField === 'false';
            else throw `boolEq passed invalid boolVal ${boolVal}`;
        },
        log(x) {
            console.log(x);
        },
        getInputId(field) {
            return `${field.instance_class}-${field.name}-${field.id}`;
        },

        safeGoto(routeName) {
            if (this.$route.name != routeName) {
                this.$router.push({ name: routeName });
            }
        },
    },
});

Vue.config.productionTip = false;
new Vue({
    el: '#app',
    router,
    store,
    ValidationObserver,
    ValidationProvider,
    render: (h) => h(App),
});
