<script>
/* Allows vue components from the backend to be rendered on the
 * frontend. */
import VueWithCompiler from 'vue/dist/vue.esm';

export default {
    props: {
        html: {
            type: String,
            default: '',
        },
        textProps: {
            type: Object,
            required: false,
        },
    },
    data() {
        return { templateRender: undefined };
    },
    watch: {
        html(to) {
            this.updateRender();
        },
    },
    created() {
        this.updateRender();
    },
    methods: {
        updateRender() {
            const compiled = VueWithCompiler.compile(this.html);
            this.templateRender = compiled.render;
            this.$options.staticRenderFns = [];
            for (const staticRenderFunction of compiled.staticRenderFns) {
                this.$options.staticRenderFns.push(staticRenderFunction);
            }
        },
    },
    render() {
        return this.templateRender();
    },
};
</script>
