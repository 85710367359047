import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, max, max_value } from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    message: 'This field is required',
});

extend('email', {
    ...email,
    message: 'Please provide a valid email',
});

extend('max', max);

extend('max_value', {
    ...max_value,
    message: 'Should be less than {max}',
});

function isValidDate(dateString) {
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;
    var parts = dateString.split('/');
    var day = parseInt(parts[1], 10);
    var month = parseInt(parts[0], 10);
    var year = parseInt(parts[2], 10);
    if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;
    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
        monthLength[1] = 29;
    return day > 0 && day <= monthLength[month - 1];
}

extend('date', {
    validate: (value) => {
        return isValidDate(value);
    },
    message: 'Date must be in mm/dd/yyyy format',
});

extend('password', {
    validate: (value) => {
        console.log('yo');
        const passwordRegEx = new RegExp(
            // /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?._&])[A-Za-z\d@$!%*#?._&]{8,}$/,
        );
        return passwordRegEx.test(value);
    },
    message:
        'Must be at least 8 characters and should contain at least 1 letter, 1 number and 1 special character',
});

extend('number', {
    validate: (value) => {
        if (typeof value == 'number') {
            return true;
        }
        if (typeof value != 'string') return false;

        for (var i = 0; i < value.length; i++) {
            var code = value[i].charCodeAt(0);
            if (code == 69 || code == 101) {
                return false;
            }
        }
        value = value.replace(/,/g, '');
        return !isNaN(value) && !isNaN(parseFloat(value));
    },
    message: 'Please enter a number',
});

extend('integer', {
    validate: (value) => {
        if (typeof value == 'number') {
            return Number.isInteger(value) && value >= 0;
        }
        if (typeof value != 'string') return false;
        for (var i = 0; i < value.length; i++) {
            var code = value[i].charCodeAt(0);
            if (code == 69 || code == 101 || code == 46 || code == 45) {
                return false;
            }
        }
        value = value.replace(/,/g, '');
        return !isNaN(value) && !isNaN(parseFloat(value));
    },
    message: 'Please enter a whole number',
});

extend('positive', {
    validate: (value) => {
        if (typeof value == 'number') {
            return value >= 0;
        }
        if (typeof value != 'string') return false;
        for (var i = 0; i < value.length; i++) {
            var code = value[i].charCodeAt(0);
            if (code == 69 || code == 101 || code == 45) {
                return false;
            }
        }
        value = value.replace(/,/g, '');
        return !isNaN(value) && !isNaN(parseFloat(value));
    },
    message: 'Please enter a positive number',
});

extend('email', {
    validate: (value) => {
        const emailRegEx = new RegExp(/^[^\s@]+@[^\s@]+$/, 'i');
        return emailRegEx.test(value);
    },
    message: 'Please enter a valid email',
});

Vue.component('ValidationProvider', ValidationProvider);

Vue.component('ValidationObserver', ValidationObserver);
