function formatDollarAmount(x) {
    x = x.toFixed(2);

    var xs = Math.abs(x)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if (x < 0) {
        return `- $${xs}`;
    }
    return '$' + xs;
}

function displayDocType(type) {
    switch (type) {
        case 'Academic':
            return 'Scholarship/Grant';
        case 'Commissions':
            return 'Fees/Commissions';
        case 'Award':
            return 'Prize/Award';
        case 'MultiB1099':
            return '1099-B';
        case 'AuPairIncome':
            return 'Au Pair Income';
    }
    return type;
}

function prepareInterviewLabel(label) {
    return `<span>${label}</span>`;
}

function strToNum(num) {
    if (num == null) {
        return num;
    }
    if (typeof num == "number") {
        return num;
    }
    return Number(num.replace(/,/g, ''));
}

export { formatDollarAmount, displayDocType, prepareInterviewLabel, strToNum };
