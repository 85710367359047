import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import versioningTimer from "@/services/versioning";
import routes from "./routes";

Vue.use(VueRouter);

import VueMeta from 'vue-meta';
Vue.use(VueMeta);

const baseUrl = "";

const router = new VueRouter({
	mode: "history",
	base: baseUrl,
	routes,
	scrollBehavior(to) {
		return new Promise(() => {
			if (to.hash) {
				window.scroll({ top: 0 });
			} else {
				document.querySelector("html").style.scrollBehavior = "auto";
				window.scroll({ top: 0 });
				document.querySelector("html").style.scrollBehavior = "";
			}
		});
	},
});
const TOKEN_LIFETIME_LEFT_INITIAL = 2 * 60 * 60;

router.beforeEach((to, from, next) => {
	versioningTimer.runTimersCheck();

	if (
		!to.meta.allowAnonymous &&
		!store.getters.isAuthenticated(TOKEN_LIFETIME_LEFT_INITIAL)
	) {
		next({
			name: "Login",
			query: { redirect: to.fullPath },
		});
	} else if (to.meta.requireStaff) {
		store.getters.client.get("/is-superuser/").then((response) => {
			if (response.data.is_superuser) {
				next();
			} else {
				next({
					name: "Login",
					query: { redirect: to.fullPath },
				});
			}
		});
	} else {
		next();
	}
});

const DEFAULT_TITLE = "Thriftax";

router.afterEach((to, from) => {
	Vue.nextTick(() => {
		if (to.meta && to.meta.title) {
			document.title = to.meta.title;
		} else {
			document.title = DEFAULT_TITLE;
		}
	});
});

export default router;
