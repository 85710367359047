<template>
    <validation-provider
        :ref="id"
        :name="id"
        :v-id="id"
        :rules="validationRules"
        v-slot="{ errors }"
        @click="$emit('click')"
    >
        <div class="radio-select" :class="inline ? 'radio-select-inline' : ''">
            <div class="radio-select-label">
                <slot name="label"></slot>
            </div>
            <div class="radio-choices">
                <div
                    v-for="item in choices"
                    :key="item[0]"
                    class="form-check"
                >
                    <div class="custom-control custom-radio">
                        <input
                            :value="item[0]"
                            class="custom-control-input"
                            v-model="internalVal"
                            @input="$emit('input', $event.target.value)"
                            :name="id"
                            :id="id + '_' + item[0]"
                            type="radio"
                            :disabled="disabled"
                        />
                        <label
                            class="custom-control-label"
                            :for="id + '_' + item[0]"
                            >{{ item[1] }}</label
                        >
                    </div>
                </div>
            </div>
        </div>
        <small class="form-text text-danger mt-0 pt-0 pl-2">{{
            errors[0]
        }}</small>
    </validation-provider>
</template>

<script>
export default {
    props: {
        inline: {
            type: Boolean,
            default: true,
        },
        value: {
            required: true,
        },
        id: {
            type: String,
            default: null,
        },
        validationRules: {
            type: String,
            default: ``,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        choices: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            internalVal: null,
        };
    },
    mounted() {
        this.internalVal = this.value;
        this.$watch('value', to => {
            this.internalVal = to;
        });
    },
};
</script>

<style scoped>
.custom-radio
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: rgba(55, 125, 255, 0.5) !important;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: rgb(47, 116, 245) !important;
    border-color: rgba(55, 125, 255, 0.5) !important;
}
</style>
