<template>
    <text-input
        :data-mask="field.data_mask"
        :placeholder="field.placeholder"
        :id="getInputId(field)"
        :value="data"
        @blur="$emit('blur')"
        @input="
            $emit('update:data', $event);
            $emit('input', $event);
        "
        @keypress="isEnglish($event)"
        :disabled="$store.getters.formsPurchased"
        :validation-rules="field.validation_rules"
    >
        <template v-slot:label>
            <dyn-render v-if="field.label" :html="field.label"></dyn-render>
            <span v-else-if="fillTop">&shy;</span>
        </template>
    </text-input>
</template>

<script>
export default {
    props: {
        field: { type: Object, required: true },
        data: { required: true },
        fillTop: { type: Boolean, default: false },
    },
    methods: {
        isEnglish(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            var length;
            if (this.data) {
                length = this.data.length;
            } else {
                length = 0;
            }
            if (charCode > 126 || length >= this.field.max_length) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    },
};
</script>
</script>
