<template>
    <div>
        <date-picker
            :input-debounce="200"
            :hidePopover="true"
            v-model="internalVal"
            :model-config="modelConfig"
            @input="$emit('update:data', $event)"
        >
            <template v-slot="{ togglePopover, hidePopover }">
                <text-input
                    input-class="field-icon-prepend-lg"
                    placeholder="mm/dd/yyyy"
                    :value="calendarData"
                    @input="updateVal($event)"
                    @keydown="launchPrevents($event)"
                    @keyup="formatDate($event)"
                    @focus="hidePopover()"
                    :id="getInputId(field)"
                    :disabled="$store.getters.formsPurchased"
                    :validation-rules="getRules"
                    ref="inputComponent"
                    :add-errors="field.addErrors"
                >
                    <template v-slot:label>
                        <dyn-render
                            v-if="field.label"
                            :html="field.label"
                        ></dyn-render>
                        <span v-else-if="fillTop">&shy;</span>
                    </template>
                    <template v-slot:input-icon>
                        <i
                            @click="
                                if (!$store.getters.formsPurchased) {
                                    togglePopover();
                                }
                            "
                            class="far fa-calendar-alt input-icon input-icon-btn"
                        ></i>
                    </template>
                </text-input>
            </template>
        </date-picker>
    </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

export default {
    props: {
        field: {
            type: Object,
            required: true,
        },
        data: {
            required: true,
        },
        fillTop: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            modelConfig: {
                type: 'string',
                mask: 'MM/DD/YYYY',
            },
            internalVal: null,
            newVal: null,
        };
    },
    computed: {
        getRules() {
            if (this.field.validation_rules.length) {
                return this.field.validation_rules + '|date';
            }
            return 'date';
        },
        calendarData() {
            return this.internalVal;
        },
    },
    methods: {
        launchPrevents(event) {
            var charCode = event.which ? event.which : event.keyCode;
            if (charCode == 9) return;
            if (charCode != 8 && charCode != 37 && charCode != 39) {
                if ((charCode < 47 || charCode > 57) && charCode != 191) {
                    event.preventDefault();

                    return;
                }

                var len = 0;
                if (this.internalVal) {
                    len = this.internalVal.length;
                }

                if (len >= 10) {
                    var selectedText;

                    var inputElement = this.$refs.inputComponent.$refs.input;

                    if (
                        inputElement.value.substring(
                            inputElement.selectionStart,
                            inputElement.selectionEnd,
                        )
                    ) {
                        selectedText = inputElement.value.substring(
                            inputElement.selectionStart,
                            inputElement.selectionEnd,
                        );
                    } else if (window.getSelection) {
                        selectedText = window.getSelection().toString();
                    } else if (
                        document.selection &&
                        document.selection.type != 'Control'
                    ) {
                        selectedText = document.selection.createRange().text;
                    }

                    if (!selectedText) {
                        event.preventDefault();
                        return;
                    }
                }
                if (len !== 1 || len !== 3) {
                    if (charCode == 47) {
                        event.preventDefault();
                        return;
                    }
                }
                var numSlashes = this.getNumSlashes(this.internalVal);
                if (
                    charCode != 191 &&
                    (len == 2 || len == 5) &&
                    numSlashes < 2 &&
                    event.target.selectionStart == event.target.value.length
                ) {
                    this.internalVal += '/';
                }
                if (charCode == 191 && numSlashes >= 2) {
                    event.preventDefault();
                }
            }
        },
        getNumSlashes(val) {
            var numSlashes = 0;
            if (val) {
                var len = val.length;
                for (var i = 0; i < len; i++) {
                    if (val[i].charCodeAt(0) == 47) {
                        numSlashes++;
                    }
                }
            }
            return numSlashes;
        },
        formatDate(event) {
            if (!this.internalVal) {
                return;
            }

            var charCode = event.which ? event.which : event.keyCode;
            // return false;

            if (
                charCode != 8 &&
                charCode != 37 &&
                charCode != 39 &&
                charCode != 191 &&
                event.target.value.length == event.target.selectionStart
            ) {
                // If we're at a particular place, let the user type the slash
                // i.e., 12/12/1212

                var len = this.internalVal.length;
                // If they don't add the slash, do it for them...
                if (len === 2 || len === 5) {
                    var numSlashes = this.getNumSlashes(this.internalVal);
                    if (numSlashes < 2) {
                        this.internalVal += '/';
                    }
                }
                // If they don't add the slash, do it for them...
            }
        },
        updateVal(event) {
            this.$emit('update:data', event);
            this.internalVal = event;
        },
    },
    mounted() {
        this.internalVal = this.data;
        if (this.internalVal && this.internalVal.includes('-')) {
            var comps = this.internalVal.split('-');
            this.internalVal = `${comps[1]}/${comps[2]}/${comps[0]}`;
        }
        this.$watch('data', (newVal) => {
            this.internalVal = newVal;
            if (this.internalVal && this.internalVal.includes('-')) {
                var comps = this.internalVal.split('-');
                this.internalVal = `${comps[1]}/${comps[2]}/${comps[0]}`;
            }
        });
    },
    components: {
        DatePicker,
    },
};
</script>

