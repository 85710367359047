import ActivityBasedTimer from "@/services/ActivityBasedTimer";
import store from "@/store";
import { unauthClient } from "@/api";

/* this should be changed by os command, as this should always be the same as the version
in APIApp\views.py */
const CLIENT_VERSION = 42;

const versioningTimer = ActivityBasedTimer();

async function isNewerVersionAvailable() {
	return unauthClient.get("/client-version/").then((resp) => {
		return resp.data.version != CLIENT_VERSION;
	});
}

versioningTimer.setInterval({
	async callback(params) {
		const newVersionAvailable = await isNewerVersionAvailable();
		if (!newVersionAvailable) {
			return;
		}
		if (params.forced) {
			window.location.reload();
		}

		store.commit("newVersionAvailable", true);
	},
	// Normal interval is once every 10 minutes
	interval: 1000 * 60 * 10,
	// Forced interval is once every 1.5 hours
	forcedInterval: 1000 * 60 * 60 * 1.5,
});

export { CLIENT_VERSION };
export default versioningTimer;
