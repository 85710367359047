function prepareForm(responseSections) {
    let sections = [];
    responseSections.forEach((section) => {
        let data = {};
        section.forEach((field) => {
            data[field.name] = field.value;
            field.addErrors = [];
            field.disabled = false;
        });
        let sectionData = {
            data: data,
            schema: section,
        };
        sections.push(sectionData);
    });
    return sections;
}

const INVALID_NO_MESSAGE = [''];
const VALID = [];

function prepareFormset(responseFormset) {
    let formset = [];

    if (responseFormset && responseFormset.length) {
        responseFormset.forEach((form) => {
            let sections = prepareForm(form.sections);
            let newForm = {
                id: form.id,
                displayId: form.id,
                sections: sections,
            };
            if ('delete' in form) {
                newForm.delete = form.delete;
            }
            for (let key in form) {
                if (
                    Object.prototype.hasOwnProperty.call(form, key) &&
                    key != 'sections'
                ) {
                    newForm[key] = form[key];
                }
            }
            formset.push(newForm);
        });

        if (formset.length && 'delete' in formset[0]) {
            formset[formset.length - 1].TEMPLATE = true;
        }
    }
    return formset;
}

function cleanIncomeDocs(responseData) {
    return { docs: responseData.docs, context: responseData.context };
}

function cleanFormData(responseData) {
    if (responseData.page_name == 'income') {
        return cleanIncomeDocs(responseData);
    }

    let sections = [];
    if ('sections' in responseData) {
        sections = prepareForm(responseData.sections);
    }
    let formsets = {};
    if ('formsets' in responseData) {
        for (let formset in responseData.formsets) {
            formsets[formset] = prepareFormset(responseData.formsets[formset]);
        }
    }

    var payload = {
        sections: sections,
        context: responseData.context,
        formsets: formsets,
    };

    if ('non_form_fields' in responseData) {
        payload.nonFormFields = responseData.non_form_fields;
    }

    return payload;
}

export { prepareForm, VALID, INVALID_NO_MESSAGE };

export default cleanFormData;
