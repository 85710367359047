<template>
    <checkbox-input
        :value="data"
        @input="$emit('update:data', $event)"
        :disabled="$store.getters.formsPurchased"
        :id="field.name + '_' + field.id"
    >
        <template v-slot:label>
            <dyn-render v-if="field.label" :html="field.label"></dyn-render>
        </template>
    </checkbox-input>
</template>

<script>
export default {
    props: ['data', 'field'],
};
</script>
