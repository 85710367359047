<template>
    <v-popover
        trigger="hover"
        :popoverClass="getClass"
        :popoverInnerClass="getInnerClass"
        placement="top"
        offset="0"
    >
        <span class="link"><slot></slot></span>
        <template slot="popover">
            <slot name="content"></slot>
        </template>
    </v-popover>
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            default: ``,
        },
    },

    computed: {
        getInnerClass() {
            var classes = [];

            classes.push(`tooltip-inner`);

            if (this.size == 'lg') {
                classes.push(`tooltip-inner-lg`);
            }
            return classes;
        },

        getClass() {
            var classes = [];

            classes.push(`tooltip`);

            if (this.size == 'lg') {
                classes.push(`tooltip-lg`);
            }
            return classes;
        },
    },
};
</script>
