export default {
	state: {
		clientToken: null,
		backendError: null,
	},
	mutations: {
		paymentClientToken(state, token) {
			state.clientToken = token;
		},
		checkoutBackendError(state, error) {
			state.backendError = error;
		},
	},
	getters: {
		paymentClientToken(state) {
			return state.clientToken;
		},
		checkoutBackendError(state) {
			return state.backendError;
		},
	},
	actions: {
		fetchPaymentClientToken(context) {
			return context.rootGetters.client
				.get('/payment-client-token/')
				.then((resp) => {
					context.commit(
						'paymentClientToken',
						resp.data.client_token,
					);
					return resp.data.client_token;
				});
		},
	},
};
