<template>
    <radio-input
        :choices="field.choices"
        :validation-rules="field.validation_rules"
        :value="data"
        @input="$emit('update:data', $event)"
        :id="getInputId(field)"
        :disabled="$store.getters.formsPurchased"
        :inline="false"
    >
        <template v-slot:label>
            <dyn-render :html="field.label" />
        </template>
    </radio-input>
</template>

<script>
export default {
    props: {
        data: {
            required: true,
        },
        field: {
            type: Object,
            required: true,
        },
    },
};
</script>
