<template>
	<validation-provider
		class="w-100"
		:rules="field.validation_rules"
		v-slot="{ errors }"
	>
		<div class="js-form-message w-100 mb-0 pb-0">
			<div class="form-group pb-0 mb-0 radio-right">
				<div
					v-for="(item, index) in field.choices"
					:key="item[0]"
					class="form-check form-check-inline"
				>
					<div class="custom-control custom-radio">
						<input
							:value="item[0]"
							class="custom-control-input"
							v-model="data"
							@input="$emit('update:data', $event.target.value); setDirty();"
							:name="field.name + field.id"
							:id="field.input_id + index"
							type="radio"
							:disabled="isPurchased"
						/>
						<label
							class="custom-control-label"
							:for="field.name + '_' + index + '_' + field.id"
							>{{ item[1] }}</label
						>
					</div>
				</div>
			</div>
			<small class="form-text text-danger mt-0 pt-0">{{ errors[0] }}</small>
		</div>
	</validation-provider>
</template>

<script>
export default {
	props: ["data", "field"],
	computed: {
		isPurchased() {
			return this.$store.getters.formsPurchased;
		},
		radioButtonValue: {
			get: function() {
				return this.data;
			},
			set: function() {
				// Communicate the change to parent component so that selectedValue can be updated
				this.$emit("change", this.label);
			},
		},
	},
	methods: {
		setDirty() {
			this.$refs.validator.setFlags({ touched: true });
		},
	},
};
</script>

<style scoped>
.custom-radio
	.custom-control-input:disabled:checked
	~ .custom-control-label::before {
	background-color: rgba(55, 125, 255, 0.5) !important;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
	background-color: rgb(47, 116, 245) !important;
	border-color: rgba(55, 125, 255, 0.5) !important;
}

.js-form-message {
	display: flex;
	justify-content: space-between;
}
/* iphone x portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
	/* STYLES GO HERE */
	.custom-field-label {
		padding-right: 5%;
		width: 100%;
	}
	.js-form-message {
		justify-content: space-around;
	}
	.radio-right {
		max-width: 20%;
		padding: 0 3%;
	}
}
</style>
