import textInput from './fly-text-input';
import selectInput from './fly-select-input';
import dateInput from './fly-date-input';
import radioInput from './fly-radio-input';
import chunkyRadioInput from './fly-chunky-radio-input';
import integerInput from './fly-integer-input';
import floatInput from './fly-float-input';
import internationalPhoneInput from './fly-international-phone-input';
import squishedRadioInput from './fly-squished-radio-input';
import checkboxInput from './fly-checkbox-input';
import floatInputNonCash from './fly-float-input-non-cash';
import unlabelledRadioInput from './fly-unlabelled-radio';
import searchSelect from './fly-search-select';
import positiveFloat from './fly-positive-float';

export default {
    textInput,
    selectInput,
    dateInput,
    radioInput,
    chunkyRadioInput,
    integerInput,
    floatInput,
    internationalPhoneInput,
    squishedRadioInput,
    checkboxInput,
    floatInputNonCash,
    unlabelledRadioInput,
    searchSelect,
    positiveFloat,
};

var numberInputs = [
    'integer-input',
    'float-input',
    'float-input-non-cash',
    'positive-float',
];

export { numberInputs };
