<template>
	<footer class="bg-dark mt-4">
		<div class="container">
			<div class="space-1">
				<div class="row align-items-md-center mb-7">
					<div class="mb-4 mb-md-0">
						<!-- Nav Link -->
						<ul class="nav nav-sm nav-white nav-x-sm align-items-center">
							<li class="nav-item">
								<router-link :to="{ name: 'PrivacyPolicy' }">
									<a class="nav-link" href="#">Privacy Policy</a>
								</router-link>
							</li>
							<li class="nav-item opacity mx-3">&#47;</li>
							<li class="nav-item">
								<router-link :to="{ name: 'TermsAndConditions' }">
									<a class="nav-link" href="#">Terms And Conditions</a>
								</router-link>
							</li>
							<li class="nav-item opacity mx-3">&#47;</li>
							<li class="nav-item">
								<router-link :to="{ name: 'FAQHome' }">
									<a class="nav-link" href="#">FAQ</a>
								</router-link>
							</li>
							<li class="nav-item opacity mx-3">&#47;</li>
							<li class="nav-item">
								<router-link :to="{ name: 'Contact' }">
									<a class="nav-link" href="#">Contact Us</a>
								</router-link>
							</li>
							<li class="nav-item opacity mx-3">&#47;</li>
							<li class="nav-item">
								<router-link :to="{ name: 'About' }">
									<a class="nav-link" href="#">About</a>
								</router-link>
							</li>
						</ul>
						<!-- End Nav Link -->
					</div>
				</div>

				<!-- Copyright -->
				<div class="w-md-75 text-lg-center mx-lg-auto">
					<p class="text-white opacity-sm small">
						When you visit or interact with our sites, services or tools, we or
						our authorised service providers may use cookies for storing
						information to help provide you with a better, faster and safer
						experience and for marketing purposes.
					</p>
				</div>
				<p class="text-white opacity-sm small text-center">
					© {{ (new Date).getFullYear() }} All rights reserved to thriftax.com
				</p>
				<!-- End Copyright -->
			</div>
		</div>
	</footer>
</template>

<script>
export default {};
</script>
