<template>
    <float-input v-on="$listeners" :field="field" :data="data" />
</template>

<script>
import floatInput from './fly-float-input';

export default {
    props: {
        field: Object,
        data: [String, Number],
    },
    components: { floatInput },

    created() {
        var rules;
        if (this.field.validation_rules) {
            rules = `positive|${this.field.validation_rules}`;
        } else {
            rules = 'positive';
        }

        this.field.validation_rules = rules;
    },
};
</script>
