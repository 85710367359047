<template>
  <validation-provider
    ref="validator"
    :name="id"
    :rules="validationRules"
    v-slot="{ errors }"
    @click="$emit('click')"
  >
    <div class="input-icons">
      <label class="input-label input-label-spacing">
        <slot name="label"> </slot>
      </label>
      <span class="input-icon-prepend">
        <slot name="input-icon"> </slot>
      </span>
      <input
        class="form-control custom-field m-0"
        :class="getClass(errors.length)"
        :placeholder="placeholder"
        v-mask="dataMask"
        :id="id"
        :type="inputType"
        :value="value"
        @input="$emit('input', $event.target.value)"
        @blur="$emit('blur')"
        @keypress="$emit('keypress', $event)"
        @keyup="$emit('keyup', $event)"
        @keydown="$emit('keydown', $event)"
        @focus="$emit('focus')"
        ref="input"
        :readonly="readonly"
        :disabled="disabled"
      />
      <span class="input-icon-append">
        <slot name="input-icon-append"> </slot>
      </span>
    </div>
    <span class="row flex-row my-0 mx-1 text-danger small">{{
      getError(errors)
    }}</span>
  </validation-provider>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number]
    },
    validationRules: {
      type: String,
      default: ``
    },
    placeholder: {
      type: String
    },
    inputClass: {
      type: String,
      default: ``
    },
    inputType: {
      type: String,
      default: `text`
    },
    dataMask: {
      type: String,
      default: ``
    },
    disabled: {
      type: Boolean,
      default: false
    },
    addErrors: {
      type: Array,
      default: () => {
        return [];
      }
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getClass(hasErrors) {
      var classes = [];
      if (this.addErrors.length || hasErrors) classes.push(`is-invalid`);
      classes.push(this.inputClass);
      return classes;
    },
    getError(validatorErrors) {
      if (validatorErrors.length) {
        return validatorErrors[0];
      } else if (this.addErrors.length) {
        return this.addErrors[0];
      }
      return ``;
    }
  },

  mounted() {
    this.$refs.validator.reset();
  }
};
</script>

<style>
.input-icons .input-icon-prepend i {
  position: absolute;
}

.input-icons {
  width: 100%;
  position: relative;
}

.input-icons .input-icon-append {
  position: absolute;
  right: 0;
  margin-right: 11px;
  margin-top: -35px;
}
</style>
