<template>
    <validation-provider
        ref="validator"
        :rules="validationRules"
        v-slot="{ errors }"
    >
        <label class="input-label input-label-spacing">
            <slot name="label"> </slot>
        </label>
        <textarea
            class="form-control custom-field m-0"
            :class="getClass(errors.length)"
            :placeholder="placeholder"
            :rows="rows"
            v-mask="dataMask"
            :id="id"
            :type="inputType"
            :value="value"
            @input="$emit('input', $event.target.value)"
            @blur="$emit('blur')"
            @keypress="$emit('keypress', $event)"
            ref="input"
            :readonly="readonly"
            :disabled="disabled"
        />
        <span class="row flex-row my-0 mx-1 text-danger small">{{
            getError(errors)
        }}</span>
    </validation-provider>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            default: null,
        },
        rows: {
            type: Number,
            default: 3,
        },
        value: {
            type: String,
        },
        validationRules: {
            type: String,
            default: ``,
        },
        placeholder: {
            type: String,
        },
        inputClass: {
            type: String,
            default: ``,
        },
        inputType: {
            type: String,
            default: `text`,
        },
        dataMask: {
            type: String,
            default: ``,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        addErrors: {
            type: Array,
            default: () => {
                return [];
            },
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        getClass(hasErrors) {
            var classes = [];

            if (this.addErrors.length || hasErrors) classes.push(`is-invalid`);

            classes.push(this.inputClass);

            return classes;
        },
        getError(validatorErrors) {
            if (validatorErrors.length) {
                return validatorErrors[0];
            } else if (this.addErrors.length) {
                return this.addErrors[0];
            }
            return ``;
        },
    },
};
</script>
