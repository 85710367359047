import Vue from 'vue';
import Button from './ui/button';
import textInput from './forms/text-input';
import selectInput from './forms/select-input';
import checkboxInput from './forms/checkbox-input';
import textArea from './forms/text-area';
import loading from './loading';
import fsDelete from './fly-form/formset-delete';
import modal from './ui/modal';
import mdi from './ui/mdi';
import badge from './ui/badge';
import toast from './ui/toast';
import dynRender from './dyn-render';
import headerTooltip from './ui/header-tooltip.vue';
import tooltip from './ui/tooltip.vue';
import radioInput from './forms/radio-input.vue';
import errorBanner from './ui/error-banner';
import errorCard from './ui/error-card';
import footer from './landings/footer';
import header from './landings/header';
import flyCol from '@/components/fly-form/fly-column';
import flyRow from '@/components/fly-form/fly-row';
import flyInput from '@/components/fly-form/fly-input';

Vue.component('fly-row', flyRow);
Vue.component('fly-col', flyCol);
Vue.component('fly-input', flyInput);

Vue.component('btn', Button);
Vue.component('t-footer', footer);
Vue.component('t-header', header);
Vue.component('text-input', textInput);
Vue.component('select-input', selectInput);
Vue.component('checkbox-input', checkboxInput);
Vue.component('text-area', textArea);
Vue.component('loading', loading);
Vue.component('fs-delete', fsDelete);
Vue.component('modal', modal);
Vue.component('mdi', mdi);
Vue.component('badge', badge);
Vue.component('toast', toast);
Vue.component('dyn-render', dynRender);
Vue.component('header-tooltip', headerTooltip);
Vue.component('tooltip', tooltip);
Vue.component('radio-input', radioInput);
Vue.component('error-banner', errorBanner);
Vue.component('error-card', errorCard);
