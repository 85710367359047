import Vue from 'vue';
import Vuex from 'vuex';
import Auth from './modules/auth.js';
import QuestionnaireMeta from './modules/interview/meta';
import Payment from './modules/payment.js';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		redirectingFromInterview: false,
		newVersionAvailable: false,
	},
	mutations: {
		newVersionAvailable(state, val) {
			state.newVersionAvailable = val;
		},
		updateRedirectingFromInterview(state, status) {
			state.redirectingFromInterview = status;
		},
	},
	getters: {
		newVersionAvailable(state) {
			return state.newVersionAvailable;
		},
		redirectingFromInterview(state) {
			return state.redirectingFromInterview;
		},
	},
	actions: {},
	modules: { Auth, QuestionnaireMeta, Payment },
});
