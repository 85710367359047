<template>
    <div v-if="errors.length || !!this.$slots.default">
        <div class="rounded border border-danger p-3 h5 text-danger">
            <template v-if="!!$slots.default">
                <div class="text-center">
                    <i class="fas fa-exclamation-triangle bs-pr-2"></i>
                    <slot> </slot>
                </div>
            </template>
            <template v-else>
                <div class="w-lg-50 mx-auto" v-if="errors.length > 1">
                    <i class="fas fa-exclamation-triangle bs-pr-2"></i> Please
                    correct the following issues:
                    <ul class="pt-2">
                        <li v-for="error in errors" :key="error">
                            {{ error }}
                        </li>
                    </ul>
                </div>
                <div v-else class="text-center">
                    <i class="fas fa-exclamation-triangle pr-2"></i>
                    {{ errors[0] }}
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        errors: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
};
</script>
